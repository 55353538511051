import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {ConsultaDfe} from "../../model/ConsultaDfe";

@Injectable()
export class ParticipantProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiParticipant: string = "/involved/v1/participant/";
  idEmpresa: string = localStorage.getItem('idEmpresa');

  getAllParticipants() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiParticipant, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getParticipantById(id: string) {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiParticipant  + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getParticipantsByIds(ids: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("commands", JSON.stringify(ids));

    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiParticipant, {
        params: httpParams,
        headers: {Authorization: this.oauthService.authorizationHeader()}
      })
        .subscribe((result: ConsultaDfe[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getParticipantsByCpfCnpj(cpfsCpnjs: any[]) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiParticipant}`, {headers:  {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          const retorno = result.filter(x => cpfsCpnjs.includes(x.cpfCNPJParticipant));
          resolve(retorno);
        },
        (error) => {
          reject(error);
        });
    });
  }

  getParticipantsByPage(search: string, page: number) {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
      if(search != undefined) params = search.length >= 3 ? params.append('search', search) : params;
      params = params.append('page', page.toString());

      this.http.get(environment.apiLogin + this.apiParticipant + 'pageable', 
                    {
                      headers: {Authorization: this.oauthService.authorizationHeader()}, 
                      params: params
                    })
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postParticipant(participant: string) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiParticipant, participant, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putParticipant(participant: string) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiParticipant, participant, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }


  delParticipant(id: number) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiParticipant + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAccountParticipants(id){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiParticipant + 'participants-by-account-id/'+ id , {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAccountsHistoricsByParticipants(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiParticipant + 'accounts-historics-by-participants', obj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

}
