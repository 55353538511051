import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { InsertedKeyDTO } from 'src/model/dto/InsertedKeyDTO';
import { PessoaFiscalDTO } from 'src/model/dto/PessoaFiscalDTO';
import { MemberProvider } from 'src/providers/company/member/member';
import { AccessKeyProvider } from 'src/providers/dfe/access-key/access-key';
import { ErrorUtil } from 'src/util/error';
import { ConvertObjectPipe } from 'src/util/pipes/ConvertObjectPipe';
import { Snackbar } from 'src/util/snackbar';
import Swal from 'sweetalert2'
import {ConsultDateUtil} from "../../../../../util/consultDateUtil";

@Component({
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event)'
  },
  selector: 'app-access-key-modal',
  templateUrl: './access-key-modal.component.html',
  styleUrls: ['./access-key-modal.component.scss']
})
export class AccessKeyModalComponent implements OnInit {

  @ViewChild('selectMember', {static: true}) selectMember: MatSelect;

  public members: PessoaFiscalDTO[];
  // tslint:disable-next-line: variable-name
  public filter_member: ReplaySubject<PessoaFiscalDTO[]> = new ReplaySubject<PessoaFiscalDTO[]>(1);
  public memberFilterCtrl: FormControl = new FormControl();
  public totalNFE = 0;
  public totalCTE = 0;
  public totalDFE = 0;
  public errados = 0;
  public cpfCnpjSelecionado: string;
  public formGroup: FormGroup;
  public carregando = false;
  public finalizado = false;
  public blocked = false;

  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key == 'Escape'){
      this.activeModal.close();
    }
  }

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              private memberProvider: MemberProvider,
              private accessKeyProvider: AccessKeyProvider,
              public snackBar: Snackbar,
              public erroUtil: ErrorUtil,
              public formBuilder: FormBuilder,
              private router: Router,
              private consultDateUtil: ConsultDateUtil
              ) {}

  ngOnInit() {
    this.initializeMembers();
    this.formGroup = this.formBuilder.group({
      produtor: ['', Validators.required],
      chavesAcesso: ['', Validators.required]
    });
  }

  initializeMembers() {
    const pipe = new ConvertObjectPipe();
    this.memberProvider.getAllMembers().then((result: any[]) => {
      this.members = pipe.transform(result, 'membros').filter(x => x.certificateDTORespComp != null);
      // this.members = pipe.transform(result, 'membros');
      this.filter_member.next(this.members);
    });
  }

  checkValue() {
    (document.getElementById('accessKeysTextArea') as HTMLInputElement).value =
      (document.getElementById('accessKeysTextArea') as HTMLInputElement).value.replace(/[^0-9,\s]/g, '').replace(/-/g,'').replace(/–/g,'').replace(/\r?\n|\r/g,'Z');

     (document.getElementById('accessKeysTextArea') as HTMLInputElement).value =
      (document.getElementById('accessKeysTextArea') as HTMLInputElement).value.replace(/\s+/g, '').replace(/Z/g, '\n');

  }

  insertAccessKeys() {
    if (this.formGroup.valid) {
      const codigosAcessoRaw = (document.getElementById('accessKeysTextArea') as HTMLInputElement).value
        .replace(/[\s]/g, ',');

      const codigosAcesso = codigosAcessoRaw.split(',');

      // CHECAGEM PRA VER SE NÃO É O OFFICE PASSADO QUE TA BUSCANDO NOTA DEMAIS OS MALDITO
      if (JSON.parse(localStorage.getItem("id_token_claims_obj")).details.office.id == 6408) {
        const consultDate = this.consultDateUtil.getDateForRequest(false);
        this.carregando = true;
        this.accessKeyProvider.getAccessKeysByPeriod(consultDate.startDate, consultDate.endDate).then((res: []) => {
          if (res != null) {
            if(res.length + codigosAcesso.length > 1000){
              this.blocked = true;
              this.handleBlocked()
              return;
            } else {
              this.postAccessKeys(codigosAcesso)
            }
          }
          this.carregando = false;
        }).catch((result: HttpErrorResponse) => {
          this.carregando = false;
        });
      }
      else {
        this.postAccessKeys(codigosAcesso);
      }
    } else {
      this.snackBar.openLong('Os campos produtor e chaves de acesso são obrigatórios!', 'erro')
    }
  }

  postAccessKeys(codigosAcesso){
    this.carregando = true;
    this.updateCounters();
    this.accessKeyProvider.postByAccessKeys(this.cpfCnpjSelecionado, codigosAcesso).then((response: InsertedKeyDTO) => {
      this.carregando = false;
      Swal.fire({
        title: 'Sucesso!',
        html: `Número de chaves enviadas: ` + response.total_keys +
          `<br>Número de chaves inseridas: ` + response.inserted +
          `<br>Número de chaves não inseridas: ` + response.not_inserted,
        icon: 'success'
      })

      if (!this.router.url.includes('chave-acesso')) {
        window.location.href = '/monitor-fiscal/chave-acesso';
      } else {
        this.finalizado = true;
        this.activeModal.close(this.finalizado);
      }

    }).catch((result: HttpErrorResponse) => {
      Swal.fire({
        title: 'Erro!',
        html: `Ocorreu um erro ao inserir suas chaves!<br>${this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper')}`,
        icon: 'error'
      })
      this.carregando = false;
      this.totalNFE = 0;
      this.totalCTE = 0;
      this.totalDFE = 0;
      this.errados = 0;
    });
  }


  handleBlocked(){
    this.activeModal.close();
    Swal.fire({
      title: 'Erro!',
      html: `Limite diário de inserção de chaves excedido.`,
      icon: 'error'
    })
  }

  // comentario

  updateCounters() {
    this.totalNFE = 0;
    this.totalDFE = 0;
    this.totalCTE = 0;
    this.errados = 0;

    const codigosAcessoRaw = (document.getElementById('accessKeysTextArea') as HTMLInputElement).value
      .replace(/[\s]/g, ',');

    const codigosAcesso = codigosAcessoRaw.split(',');

    // tslint:disable-next-line: prefer-const
    for (let i in codigosAcesso) {
      if (codigosAcesso[i].length === 44) {
        if (codigosAcesso[i].charAt(20) === '5' && codigosAcesso[i].charAt(21) === '5') {
          this.totalNFE = this.totalNFE + 1;
          this.totalDFE = this.totalDFE + 1;
        } else if (codigosAcesso[i].charAt(20) === '5' && codigosAcesso[i].charAt(21) === '7') {
          this.totalCTE = this.totalCTE + 1;
          this.totalDFE = this.totalDFE + 1;
        } else {
          this.errados = this.errados + 1;
        }
      } else {
        this.errados = this.errados + 1;
      }
    }
  }

  selecionarProdutor(valor: string) {
    this.cpfCnpjSelecionado = valor;

    this.selectMember.close();
    this.selectMember.focus();
  }

  protected filterMembers() {
    if (!this.members) {
      return;
    }
    // get the search keyword
    let search = this.memberFilterCtrl.value;
    if (!search) {
      this.filter_member.next(this.members.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the members
    this.filter_member.next(
      this.members.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
