import {Component, Injectable, Injector, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs} from '@syncfusion/ej2-dropdowns';
import {Predicate, Query} from '@syncfusion/ej2-data';
import {UserblockService} from '../sidebar/userblock/userblock.service';
import {SettingsService} from '../../core/settings/settings.service';
import {MenuService} from '../../core/menu/menu.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {Snackbar} from '../../../util/snackbar';
import {DfeProvider} from '../../../providers/dfe/dfe';
import {Pessoa} from '../../../model/Pessoa';
import {UserProvider} from '../../../providers/user/user';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {CheckPermission} from '../../../service/checkPermission';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {SupportModalComponent} from './support-modal/support-modal.component';
import {HttpClient} from '@angular/common/http';
import {DarkThemePickerService} from "../../core/themes/dark-theme-picker.service";
import {CompanyNotesProvider} from "../../../providers/company/company/company-notes";
import Swal from "sweetalert2";

declare var Headway;
const screenfull = require('screenfull');


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

@Injectable()
export class HeaderComponent implements OnInit {

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  router: Router;
  dfes: number;
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Pessoa[];
  clientes: Pessoa[];
  selectedClient: any;
  isNavSearchVisible: boolean;
  date: any;
  public data: { [key: string]: Object }[];

  environment;

  @ViewChild('fsbutton', {static: true}) fsbutton;  // the fullscreen button
  @ViewChild('dropdown', {static: false}) dropdownValue;  // the fullscreen button

  currentRouter = this.routerAngular.url;
  screenfull: any;
  pt: any;
  darkTheme: boolean;
  // maps the appropriate column to fields property
  public fields: Object = {text: "nameConcat", value: "id"};
  // set the placeholder to the DropDownList input
  public text: string = "Selecione o cliente";

  public notes = {notifications: 0, expanded: false};

  constructor(public menu: MenuService,
              public userblockService: UserblockService,
              private oauthService: OAuthService,
              public routerAngular: Router,
              public userProvider: UserProvider,
              public companyNotesProvider: CompanyNotesProvider,
              public snackBar: Snackbar,
              public checkPermission: CheckPermission,
              public dfeProvider: DfeProvider,
              public http: HttpClient,
              public settings: SettingsService,
              public injector: Injector,
              public modalService: NgbModal,
              public pickThemeService: DarkThemePickerService
              //public jwt: Jwt
  ) {

    if (this.isPWAInstalled()) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Você está usando a versão instalada da aplicação. É fortemente recomendado que utilize a versão de navegador, visto que algumas funcionalidades e correções podem estar limitadas.',
        icon: 'warning',
        confirmButtonText: 'Ir ao site',
        confirmButtonColor: '#d33',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(() => {
        const url = "https://aplicativo.farmcont.com.br/login";
        const win = window.open(url, "_blank");
        if (!win) {
          window.location.href = url;
        }
      });
    }

    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.oauthService.logoutUrl = "login";
    if (this.oauthService.hasValidAccessToken()) {
      this.userProvider.getAllCompanys().then((clientes: any) => {
        this.data = clientes;
        this.clientes = clientes;
        clientes.forEach((cliente: Pessoa) => {
          cliente['nameConcat'] = cliente.id + " - " + cliente.name;
          if (this.getIdEmpresa() == cliente.id.toString()) {
            this.selectedClient = (cliente.id);
            environment.clientId = this.selectedClient;
          }
        });
      }).catch((err) => {
        console.log(err);
        console.log('Erro ao buscar empresas');
      });
      this.getAllCompanyNotesForToday();
    }
    this.environment = environment.apiNfe;
    this.darkTheme = localStorage.getItem('theme') == 'dark';
  }

  public isPWAInstalled() {
    return (window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone);
  }

  //Bind the filter event
  public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    let predicateQuery = query.where(new Predicate('name', 'contains', e.text, true).or('id', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(this.data, query);
  };

  ngOnInit() {
    setTimeout(() => {
      Headway.init();
    });
    this.date = new Date((localStorage.getItem('workDate')));
    this.dfeProvider.getDfeRecent().then((dfes: number) => {
      this.dfes = dfes;
    });
    this.isNavSearchVisible = false;

    var ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    // Switch fullscreen icon indicator
    const el = this.fsbutton.nativeElement.firstElementChild;
    screenfull.on('change', () => {
      if (el) {
        el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        this.screenfull = screenfull.isFullscreen
      }
    });

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });

  }

  ClickRedirect() {
    console.log("teste")
    document.cookie = `access_token=${localStorage.getItem('access_token')};path=/;domain=.farmcont.com.br`
  }

  chosenYearHandler(normalizedYear) {
    if (normalizedYear != null) {
      this.snackBar.openLong('Período de trabalho atualizado!', 'success');
      localStorage.setItem('workDate', moment(normalizedYear).format());
      this.reLoad();
    } else {
      this.snackBar.openLong('Não foi possível modificar: O período de trabalho é inválido!', 'erro');
    }
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  logout() {
    this.oauthService.logOut(true);
    localStorage.clear();
    this.routerAngular.navigate(["login"]);
    this.snackBar.open('Usuário deslogado com sucesso!', 'success');
  }

  getIdEmpresa() {
    return localStorage.getItem('idEmpresa');
  }

  changeClient() {
    if (this.selectedClient != undefined) {
      let userSelected = this.data.find(value => this.selectedClient == value.id);
      if (userSelected.lcDigital) {
        localStorage.setItem('livroCaixa', "1");
      } else {
        localStorage.setItem('livroCaixa', "0");
      }
      localStorage.setItem('empresa', userSelected.name.toString());
      localStorage.setItem('idEmpresa', this.selectedClient.toString());
      this.reLoad();
    }
  }

  reLoad() {
    this.router.navigate([this.currentRouter])
  }

  getSelectedClient() {
    return environment.clientId;
  }

  openSupportModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    try {
      this.modalService.open(SupportModalComponent, modalOptions);
    } catch (error) {
      console.error('', error);
    }
  }


  noltToken() {

    const payload = {
      id: JSON.parse(localStorage.getItem("id_token_claims_obj")).details.id,
      email: JSON.parse(localStorage.getItem("id_token_claims_obj")).name,
      name: JSON.parse(localStorage.getItem("id_token_claims_obj")).details.name + " " + JSON.parse(localStorage.getItem("id_token_claims_obj")).details.lastName,
    }
    //let a = sign(payload, 'galo_cego', { algorithm: 'HS256' })
    //console.log(a)
  }

  changeTheme() {
    this.darkTheme = !this.darkTheme;
    this.pickThemeService.selectTheme(this.darkTheme);
  }

  getAllCompanyNotesForToday() {
    this.companyNotesProvider.getAllForToday().then((notes: any) => {
      this.notes.notifications = notes;
    }).catch((err) => {
      console.log(err);
    });
  }

  reloadNotes($event: string) {
    this.getAllCompanyNotesForToday();
  }

  getCompany() {
    return localStorage.getItem('idEmpresa') + " - " + localStorage.getItem('empresa');
  }

  expandNotes() {
    this.notes.expanded = !this.notes.expanded;
    this.notes.expanded ? document.getElementById('dropdown').classList.add('big') : document.getElementById('dropdown').classList.remove('big');
  }
}

