export class AutomationsConfig {

  actionList() {
    return [
      {
        action: "POSTING_DFE",
        name: "Escriturar notas",
        service: 'fiscal',
        conditions: this.dfeConditionList()
      },
      {
        action: "MOVE_CANCELLED_DFE_TO_FOLDER",
        name: "Mover notas canceladas para pasta",
        service: 'fiscal',
        conditions: []
      },
      {
        action: "MOVE_DFE_TO_FOLDER",
        name: "Mover notas para pasta",
        service: 'fiscal',
        conditions: this.dfeConditionList()
      }
    ];
  }

  dfeConditionList() {
    return [
      {
        name: "Denegada",
        operators: {isDenied: "="},
        inputType: "boolean"
      },
      {
        name: "Emitente - CPF/CNPJ",
        operators: {issuerCpfCnpjs: "=", notIssuerCpfCnpjs: "≠"},
        inputType: "select-participant-multiple"
      },
      {
        name: "Emitente - IE",
        operators: {issuerIes: "=", notIssuerIes: "≠"},
        inputType: "text-multiple"
      },
      {
        name: "Destinatário - CPF/CNPJ",
        operators: {addressedCpfCnpjs: "=", notAddressedCpfCnpjs: "≠"},
        inputType: "select-participant-multiple"
      },
      {
        name: "Destinatário - IE",
        operators: {addressedIes: "=", notAddressedIes: "≠"},
        inputType: "text-multiple"
      },
      {
        name: "CFOP",
        operators: {cfops: "="},
        inputType: "text-multiple"
      },
      {
        name: "NCM",
        operators: {ncms: "="},
        inputType: "text-multiple"
      }
    ];
  }
}
