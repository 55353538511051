import {
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
} from "@angular/core";
import { SettingsService } from "./core/settings/settings.service";
import { OAuthService } from "angular-oauth2-oidc";
import {
  menuConsultor,
  menuContador,
  menuContadorFuncionario,
  menuSuper,
} from "./routes/menu";
import { MenuService } from "./core/menu/menu.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  L10n,
  loadCldr,
  setCulture,
  setCurrencyCode,
} from "@syncfusion/ej2-base";
import { pt } from "src/util/translate/pt.json";
import { SwUpdate } from "@angular/service-worker";
import { UserProvider } from "../providers/user/user";
import { UpdateService } from "./updateService";
import { HeaderComponent } from "./layout/header/header.component";
import { MatDialog } from "@angular/material/dialog";
import { BasicAlertComponent } from "./shared/alert/basic-alert/basic-alert.component";
import { Dialog } from "../model/Dialog";
import { environment } from "../environments/environment";
import { Version } from "../model/Version";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorUtil } from "../util/error";
import * as moment from "moment";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";
import { Snackbar } from "../util/snackbar";
import Swal from "sweetalert2";
import { CheckPermission } from "src/service/checkPermission";
import {DarkThemePickerService} from "./core/themes/dark-theme-picker.service";

declare const movideskLogin: any;
declare var require: any;
// declare var gtag;
declare var Tawk_API;
declare var Tawk_LoadStart;
Tawk_API.onLoad = function () {
  Tawk_API.hideWidget();
  Tawk_API.setAttributes(
    {
      name:
        JSON.parse(localStorage.getItem("id_token_claims_obj")).details.name +
        " " +
        JSON.parse(localStorage.getItem("id_token_claims_obj")).details
          .lastName,
      email: JSON.parse(localStorage.getItem("id_token_claims_obj")).name,
      hash: "hash-value",
    },
    function (error) {}
  );
};

loadCldr(
  require("cldr-data/main/pt/numbers.json"),
  require("cldr-data/main/pt/ca-gregorian.json"),
  require("cldr-data/main/pt/currencies.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt/timeZoneNames.json")
);

setCulture("pt");
setCurrencyCode("BRL");

L10n.load({
  pt,
});

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  mySubscription;
  private isLoginDialogOpen: boolean;


  constructor(
    public settings: SettingsService,
    private oauthService: OAuthService,
    public menuService: MenuService,
    private userProvider: UserProvider,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private header: HeaderComponent,
    private errorUtil: ErrorUtil,
    public snackBar: Snackbar,
    private sw: UpdateService,
    private swUpdate: SwUpdate,
    private activatedRoute: ActivatedRoute,
    public themePicker: DarkThemePickerService,
    private checkPermissionUser: CheckPermission
      ) {
        localStorage.setItem('warnings', 'true')

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
      this.themePicker.setTheme();
    });
    this.sw.checkForUpdates();
    const navEndEvent = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvent.subscribe((event: NavigationEnd) => {
      this.changePageTitle(event);
      this.setIntercomConfig();
      this.clearStorageAfterUpdate();
    });
  }

  @HostBinding("class.layout-fixed") get isFixed() {
    return this.settings.getLayoutSetting("isFixed");
  }

  @HostBinding("class.aside-collapsed") get isCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed");
  }

  @HostBinding("class.layout-boxed") get isBoxed() {
    return this.settings.getLayoutSetting("isBoxed");
  }

  @HostBinding("class.layout-fs") get useFullLayout() {
    return this.settings.getLayoutSetting("useFullLayout");
  }

  @HostBinding("class.hidden-footer") get hiddenFooter() {
    return this.settings.getLayoutSetting("hiddenFooter");
  }

  @HostBinding("class.layout-h") get horizontal() {
    return this.settings.getLayoutSetting("horizontal");
  }

  @HostBinding("class.aside-float") get isFloat() {
    return this.settings.getLayoutSetting("isFloat");
  }

  @HostBinding("class.offsidebar-open") get offsidebarOpen() {
    return this.settings.getLayoutSetting("offsidebarOpen");
  }

  @HostBinding("class.aside-toggled") get asideToggled() {
    return this.settings.getLayoutSetting("asideToggled");
  }

  @HostBinding("class.aside-collapsed-text") get isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }

  changePageTitle(event) {
    if (event.url.includes("contador")) {
      this.titleService.setTitle("farmcont - Portal contador");
      return;
    } else if (
      this.router.url.includes("login") &&
      !window.location.href.includes("gerensia")
    ) {
      this.titleService.setTitle("farmcont");
    } else if (window.location.href.includes("gerensia")) {
      this.titleService.setTitle("gerensia");
    } else {
      this.titleService.setTitle("farmcont - Portal produtor");
    }
  }

  clearStorageAfterUpdate() {
    let versionClear = {
      version: environment.appVersion,
      clear: true,
    };
    let clearUpdateStorage = JSON.parse(
      localStorage.getItem("clearUpdateStorage")
    );
    if (
      clearUpdateStorage == null ||
      clearUpdateStorage.version != environment.appVersion
    ) {
      localStorage.setItem("gridsaldo", JSON.stringify({}));
      localStorage.setItem("gridfiscal", JSON.stringify({}));
      localStorage.setItem("gridpagreb", JSON.stringify({}));
      localStorage.setItem("gridlca", JSON.stringify({}));
      localStorage.setItem("clearUpdateStorage", JSON.stringify(versionClear));
    }
  }

  setIntercomConfig() {
    if (localStorage.getItem("id_token_claims_obj") != null) {
      const claims = JSON.parse(localStorage.getItem("id_token_claims_obj"));
      // this.intercom.boot({
      //   app_id: "ng0p9jml",
      //   'name': claims.principal.name,
      //   'last_name': claims.principal.lastName,
      //   'email': claims.principal.username,
      //   'created_at': '2020-01-01',
      //   'user_id': claims.principal.id,
      //   'office_id': claims.principal.office.id,
      //   'office': claims.principal.office.name,
      //   'last_company': localStorage.empresa,
      //   'version': environment.appVersion,
      //   'role': claims.authorities[0].authority,
      //   'superuser': (claims.authorities.find(role => role.authority === 'ROLE_SUPER') !== undefined),
      //   'event': 'dataLayerReady',
      //   // Supports all optional configuration.
      //   widget: {
      //     "activator": "#intercom"
      //   }
      // });
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    // prevent empty links to reload the page
    document.addEventListener("click", (e) => {
      const target = e.target as HTMLElement;
      if (
        target.tagName === "A" &&
        ["", "#"].indexOf(target.getAttribute("href")) > -1
      ) {
        e.preventDefault();
      }
    });

    const regra = this.givenAuthorities();
    if (regra) {
      if(!this.checkPermissionUser.isSuperuser()){
        this.userProvider.getOverdueBillings().then((result) => {
          if (result > 0) {
            let timerInterval;
            Swal.fire({
              title: "<strong>AVISO</strong>",
              icon: "warning",
              html:
                "Você poderá prosseguir em <strong></strong> segundos.<br/><br/>" +
                "Detectamos que você tem " +
                result +
                " cobranças vencidas há mais de <b>10 dias</b>. O sistema ficará <b>bloqueado</b> até que as pendências sejam quitadas. Você será <b>redirecionado</b> para o menu financeiro. <p></p>",
              focusConfirm: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: "OK",
              timer: 10000,

              onOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  Swal.getHtmlContainer().querySelector("strong").textContent = (
                    Swal.getTimerLeft() / 1000
                  ).toFixed(0);
                  //console.log((Swal.getTimerLeft() / 1000))
                  if (Swal.getTimerLeft() / 1000 < 0.1) Swal.stopTimer();
                }, 100);
                setTimeout(() => {
                  Swal.hideLoading();
                }, 10000);
              },
            }).then((result) => {
              clearInterval(timerInterval);
              this.menuService.clearMenu();
              this.router.navigate(["contador/cobrancas"]);
            });
          }
        });
      }

      if (
        regra.find((role) => role.authority === "ROLE_ACCOUNTANT_ADMIN") !==
          undefined ||
        regra.find(
          (role) => role.authority === "ROLE_ACCOUNTANT_COLLABORATOR"
        ) !== undefined ||
        regra.find((role) => role.authority === "ROLE_SUPER") !== undefined
      ) {
        this.menuService.menuItemsContador = [];
        if (
          regra.find((role) => role.authority === "ROLE_SUPER") !== undefined
        ) {
          this.menuService.addMenu(menuSuper, 2);
        } else if (
          regra.find((role) => role.authority === "ROLE_ACCOUNTANT_ADMIN") !==
          undefined
        ) {
          this.menuService.addMenu(menuContador, 2);
        } else if (
          regra.find(
            (role) => role.authority === "ROLE_ACCOUNTANT_COLLABORATOR"
          ) !== undefined
        ) {
          this.menuService.addMenu(menuContadorFuncionario, 2);
        }
      } else if (
        regra.find(
          (role) => (role.authority === "ROLE_CONSULTANT") !== undefined
        )
      ) {
        this.menuService.menuItemsConsultor = [];
        this.menuService.addMenu(menuConsultor, 4);
        this.router.navigate(["escrituracao-consultor"]);
      }
    }
    // this.menuService.menuItemsContador = [];
    // this.menuService.menuItemsConsultor = [];
    // this.menuService.addMenu(menuConsultor, 4);

    const gerensia = window.location.href.includes("gerensia");

    // Configura a aba para exibir as informações do Gerensia
    if (gerensia) {
      let favicon = document.getElementById("favicon");
      if (!favicon) {
        favicon = document.createElement("icon");
        favicon.setAttribute("rel", "icon");
        favicon.setAttribute("type", "image/x-icon");
        favicon.setAttribute("id", "favicon");
        document.getElementsByTagName("head")[0].appendChild(favicon);
      }
      favicon.setAttribute("href", "./assets/outputDir/favicon-gerensia.ico");

      let favicon32 = document.getElementById("favicon32");
      if (!favicon32) {
        favicon32 = document.createElement("icon");
        favicon32.setAttribute("rel", "icon");
        favicon32.setAttribute("type", "image/png");
        favicon32.setAttribute("id", "favicon32");
        favicon32.setAttribute("sizes", "32x32");
        document.getElementsByTagName("head")[0].appendChild(favicon32);
      }
      favicon32.setAttribute(
        "href",
        "./assets/outputDir/favicon-gerensia-32x32.png"
      );

      let favicon16 = document.getElementById("favicon16");
      if (!favicon16) {
        favicon16 = document.createElement("icon");
        favicon16.setAttribute("rel", "icon");
        favicon16.setAttribute("type", "image/png");
        favicon16.setAttribute("id", "favicon16");
        favicon16.setAttribute("sizes", "16x16");
        document.getElementsByTagName("head")[0].appendChild(favicon16);
      }
      favicon16.setAttribute(
        "href",
        "./assets/outputDir/favicon-gerensia-16x16.png"
      );

      let title = document.getElementById("titulo");
      if (!title) {
        title = document.createElement("title");
        title.setAttribute("id", "titulo");
        document.getElementsByTagName("head")[0].appendChild(title);
      }
      title.innerText = "gerensia";
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => this.checkAppVersion(event));
    }

        // Função para login automático
        // Esta função pode ser inserida logo abaixo do código do aplicativo
        // ou então ser disparada posteriormente via javascript
    movideskLogin({
      name: JSON.parse(localStorage.getItem("id_token_claims_obj")).details.name +
      " " +
      JSON.parse(localStorage.getItem("id_token_claims_obj")).details
        .lastName,
      email: JSON.parse(localStorage.getItem("id_token_claims_obj")).name,
      stayConnected: false,
      emptySubject: false,
      startChat: false, // Se o startChat for true, o aplicativo irá maximizar e a conversa irá inicializar automaticamente
    });

  }

  @HostListener("window:focus", ["$event"])
  onFocus(event: any): void {
    if (
      this.isLoginDialogOpen ||
      localStorage.getItem("empresa") == null ||
      !this.oauthService.hasValidAccessToken()
    ) {
      return;
    }
    if (this.router.url.includes("contador")) {
      return;
    }
    if (
      +localStorage.getItem("idEmpresa") !== this.header.getSelectedClient()
    ) {
      this.isLoginDialogOpen = true;
      const dialogText: Dialog = new Dialog();
      dialogText.button = "Ok";
      dialogText.header = "Atenção! A empresa foi modificada!";
      dialogText.subHeader =
        "A empresa foi modificada através do portal contador para " +
        localStorage.getItem("empresa") +
        ". A página será recarregada!";
      const dialogRef = this.dialog.open(BasicAlertComponent, {
        data: dialogText,
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((result) => {
        this.isLoginDialogOpen = false;
        this.router.navigate(["./"], {
          relativeTo: this.route,
          queryParamsHandling: "preserve",
        });
      });
    }
  }

  @HostListener("window:blur", ["$event"])
  onBlur(event: any): void {
    // Do something
  }

  givenAuthorities() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    // tslint:disable-next-line:no-string-literal
    return claims["authorities"];
  }

  calculeTime(): boolean {
    if (
      moment(localStorage.getItem("notificationCancel")).diff(
        moment().format(),
        "minutes"
      ) *
        -1 >
      5
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkAppVersion(event) {
    if (!this.isLoginDialogOpen) {
      this.isLoginDialogOpen = true;
      const dialogText: Dialog = new Dialog();
      dialogText.button = "Atualizar";
      dialogText.header = "Nova versão disponível!";
      dialogText.buttonCancel = "Cancelar";
      dialogText.subHeader =
        "Atualize para a versão mais recente " +
        event.available +
        ". Ela traz correções e melhorias de performance." +
        " A atualização leva poucos segundos e a página será recarregada e não interfere nos seus dados já salvos.";
      const dialogRef = this.dialog.open(BasicAlertComponent, {
        data: dialogText,
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((result) => {
        if (result == dialogText.button) {
          window.location.reload();
          this.isLoginDialogOpen = false;
        } else {
          localStorage.setItem("notificationCancel", moment().format());
          this.isLoginDialogOpen = false;
        }
      });
    }
  }
}
