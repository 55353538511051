import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {Injectable} from "@angular/core";

@Injectable()
export class AutomationProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAutomationsByOffice(service) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}/${service}/v1/automations/`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getAutomationByGroupId(groupId, service) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}/${service}/v1/automations/by-group-id/${groupId}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postAutomation(obj, service) {
    return new Promise((resolve, reject) => {
      resolve(true);
      this.http.post(`${environment.apiLogin}/${service}/v1/automations/`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putAutomation(obj, service) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}/${service}/v1/automations/`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteAutomation(code, service) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.apiLogin}/${service}/v1/automations/${code}`, {headers: {Authorization: this.oauthService.authorizationHeader()}}).subscribe
      ((result: any[]) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    })
  }

  putAutomations(obj, service) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}/${service}/v1/automations/update-priority`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  cloneAutomationByCode(code, obj, service) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}/${service}/v1/automations/clone/${code}`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getPostingData(companyId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}/fiscal/v1/automations/company-data/${companyId}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
