import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FilterSettingsModel, GridComponent} from '@syncfusion/ej2-angular-grids';
import Swal from 'sweetalert2';
import {CadastroAutomacaoComponent} from './cadastro-automacao/cadastro-automacao.component';
import {AutomationProvider} from 'src/providers/automation/automation';
import {Snackbar} from 'src/util/snackbar';
import {AutomationsConfig} from "../../../util/automations";
import {CloneAutomacaoComponent} from "./clone-automacao/clone-automacao.component";

@Component({
  selector: 'app-automacoes',
  templateUrl: './automacoes.component.html',
  styleUrls: ['./automacoes.component.scss']
})
export class AutomacoesComponent implements OnInit {
  public changed: boolean = false;
  public loading: boolean = false;
  public panelExpanded: boolean = true;
  public automationList = this.automationsConfig.actionList();
  public activeLink = this.automationList[0];
  public selectedAutomation: any = this.automationsConfig.actionList()[0];
  public automationData: any = [];

  @ViewChild('grid', {static: false}) public grid: GridComponent;
  public dataSourceMain: any[] = [];
  public filterSettings: FilterSettingsModel = {type: 'Menu'};


  constructor(
    public modalService: NgbModal,
    public automationProvider: AutomationProvider,
    public snackBar: Snackbar,
    public automationsConfig: AutomationsConfig
  ) {
  }

  ngOnInit() {
    this.getAutomations();
  }

  redirectYoutube(){
    window.open('https://youtu.be/yA9Pq9I5rjw?si=NKLVzAZZD2f34WmU\n', '_blank');
  }

  getAutomations() {
    this.loading = true;
    this.automationProvider.getAutomationsByOffice(this.selectedAutomation.service)
      .then((result: any[]) => {
        this.automationData = result;
        this.automationDataToTable();
        this.loading = false;
      })
      .catch((error) => {
        this.snackBar.openLong(error.message, 'erro');
        this.loading = false;
      });
  }

  automationDataToTable() {
    this.dataSourceMain = this.automationData.filter((automation) => automation.automationType === this.selectedAutomation.action).map(element => ({
      ...element,
      changed: false
    }));
  }

  changeSelectedAutomation(event) {
    this.selectedAutomation = event;
    this.automationData = [];
    this.dataSourceMain = [];
    this.getAutomations();
  }

  createAutomation() {
    const modalRef = this.modalService.open(CadastroAutomacaoComponent, {
      backdrop: 'static',
      size: 'xl',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    });
    modalRef.result.then((result) => {
      if (result) {
        this.getAutomations();
        this.snackBar.openLong('Automação criada com sucesso!', 'success');
      }
    });
  }

  updateAutomation(value) {
    this.automationProvider.getAutomationByGroupId(value.groupId, this.selectedAutomation.service)
      .then((result: any[]) => {
        const modalRef = this.modalService.open(CadastroAutomacaoComponent, {
          backdrop: 'static',
          size: 'xl',
          windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
        });
        modalRef.componentInstance.existingAutomation = result;
        modalRef.result.then((result) => {
          if (result) {
            this.getAutomations();
            this.snackBar.openLong('Automação editada com sucesso!', 'success');
          }
        })
      })
      .catch((error) => {
        this.snackBar.openLong(error.message, 'erro');
      });
  }

  deleteAutomation(value) {
    Swal.fire({
      title: 'Tem certeza que deseja deletar esta automação?',
      text: "Você não poderá reverter esta ação!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.automationProvider.deleteAutomation(value.groupId, this.selectedAutomation.service)
          .then(() => {
            Swal.fire('Deletado!', 'Sua automação foi deletada com sucesso.', 'success');
            this.getAutomations();
          })
          .catch((error) => {
            this.snackBar.openLong(error.message, 'erro');
          });
      }
    });
  }

  updateAutomationsChanges() {
    const automationList = this.grid.getRowsObject().map((row: any) => ({
      groupId: row.data.groupId,
      priority: row.index,
      isActive: row.data.isActive
    }));
    this.automationProvider.putAutomations(automationList, this.selectedAutomation.service)
      .then(() => {
        this.snackBar.openLong('Automações atualizadas com sucesso!', 'success');
        this.changed = false;
        this.getAutomations();
      })
      .catch(error => {
        this.snackBar.openLong(error.message, 'erro');
      });
  }

  cloneAutomation(value) {
    const modalRef = this.modalService.open(CloneAutomacaoComponent, {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') === 'dark' ? 'dark-theme' : ''
    });
    modalRef.componentInstance.existingAutomationGroupId = value.groupId;
    modalRef.componentInstance.automationService = this.selectedAutomation.service;
    modalRef.result.then(result => {
      if (result) {
        this.getAutomations();
        this.snackBar.openLong('Automação clonada com sucesso!', 'success');
      }
    }).catch(error => {
      this.snackBar.openLong(error.error_description, 'erro');

    })
  }
}
