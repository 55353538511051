import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {Imovel} from "../../../../model/Imovel";
import {EstadosMunicipiosUtil} from "../../../../util/estados-municipios";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {Snackbar} from "../../../../util/snackbar";
import {ErrorUtil} from "../../../../util/error";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {ReplaySubject, Subject} from "rxjs";
import {City} from "../../../../model/basicos/City";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import {Country} from "../../../../model/basicos/Country";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Estate} from "../../../../model/basicos/Estate";
import {PessoaFiscalDTO} from "../../../../model/dto/PessoaFiscalDTO";
import {MemberProvider} from "../../../../providers/company/member/member";
import {CadastroSocioComponent} from "../cadastro-socio/cadastro-socio.component";
import {ExplorationProvider} from "../../../../providers/company/exploration/exploration";
import {ExplorationDTOResponse} from "../../../../model/dto/ExplorationDTOResponse";
import {MatSelect} from "@angular/material/select";
import {ExplorationType, ExplorationTypeProd} from "../../../../model/enum/ExplorationType";
import {Dialog} from "../../../../model/Dialog";
import {BasicAlertComponent} from "../../../shared/alert/basic-alert/basic-alert.component";
import {MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'app-cadastro-imovel',
  templateUrl: './cadastro-imovel.component.html',
  styleUrls: ['./cadastro-imovel.component.scss']
})
export class CadastroImovelComponent implements OnInit {

  @ViewChild('triggerCountry', {static: false}) triggerCountry: MatAutocompleteTrigger;
  @ViewChild('triggerCity', {static: false}) triggerCity: MatAutocompleteTrigger;
  @ViewChild('triggerState', {static: false}) triggerState: MatAutocompleteTrigger;
  @ViewChild('citySelect', {static: true}) citySelect: MatSelect;
  @ViewChild('stateSelect', {static: true}) stateSelect: MatSelect;
  @ViewChild('countrySelect', {static: true}) countrySelect: MatSelect;
  @Input() enableClearOnEscapePressed = true;
  @Input() public imovelExistente;

  public imovel: ImovelDTO = new ImovelDTO();
  public imovelAux: Imovel = new Imovel();
  public codImovel: string;
  public municipioId: number;
  public editar: boolean = false;
  public imovelForm: FormGroup;
  public enviado: boolean = false;
  public properties: ImovelDTO[];
  public members: any[];
  public membersClone: any[];
  public i: number;
  public enviando: boolean = false;

  public countrys: Country[] = this.estadosMunicipiosUtil.paises;
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountrys: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public states: Estate[] = this.estadosMunicipiosUtil.estados;
  public stateFilterCtrl: FormControl = new FormControl();
  public filteredStates: ReplaySubject<Estate[]> = new ReplaySubject<Estate[]>(1);
  public cities: City[] = this.estadosMunicipiosUtil.cidades;
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredCities: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);
  protected _onDestroyCountry = new Subject<void>();
  protected _onDestroyState = new Subject<void>();
  protected _onDestroyCity = new Subject<void>();

  private terceirosExclusao: ExplorationDTOResponse[] = [];
  public pessoas;

  constructor(public activeModal: NgbActiveModal,
              private propertyProvider: PropertyProvider,
              private socioProvider: MemberProvider,
              public snackbar: Snackbar,
              public modalService: NgbModal,
              public fb: FormBuilder,
              public dialog: MatDialog,
              private explorationProvider: ExplorationProvider,
              public errorUtil: ErrorUtil,
              public estadosMunicipiosUtil: EstadosMunicipiosUtil,
              public http: HttpClient) {
  }

  get terceiros(): FormArray {
    return this.imovelForm.get('terceiros') as FormArray;
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroyCountry.next();
    this._onDestroyCountry.complete();
    this._onDestroyState.next();
    this._onDestroyState.complete();
    this._onDestroyCity.next();
    this._onDestroyCity.complete();
  }

  useCepToSetForm() {
    if (this.imovelForm.controls.cep.value.length == 8) {
      return new Promise((resolve, reject) => {
        this.http.get('https://viacep.com.br/ws/' + this.imovelForm.controls.cep.value + '/json/')
          .subscribe((result: any) => {
            resolve(result);
            //console.log("result", result);
            this.imovelForm.controls.street.setValue(result.logradouro);
            this.imovelForm.controls.neighborhood.setValue(result.bairro);
            this.imovelForm.controls.estado.setValue(this.states.find(x => x.abbreviation == result.uf));
            this.getStateId();
            this.imovelForm.controls.municipio.setValue(this.cities.find(x => x.name == result.localidade.normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
          });
      })
    }
  }

  configCountry() {
    this.estadosMunicipiosUtil.setCountry();
    this.countrys = this.estadosMunicipiosUtil.paises;
    this.filteredCountrys.next(this.countrys.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.filterCountrys();
      });
  }

  configState() {
    this.estadosMunicipiosUtil.setState();
    this.states = this.estadosMunicipiosUtil.estados;
    this.filteredStates.next(this.states.slice());
    this.stateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyState))
      .subscribe(() => {
        this.filterStates();
      });
  }

  configCity() {
    this.cities = this.estadosMunicipiosUtil.cidades;

    this.filteredCities.next(this.cities.slice());
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCity))
      .subscribe(() => {
        this.filterCities();
      });
  }

  filterCities() {
    if (!this.cities) {
      return;
    }
    // get the search keyword
    let search = this.cityFilterCtrl.value;
    if (!search) {
      this.filteredCities.next(this.cities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrys
    this.filteredCities.next(
      this.cities.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterStates() {
    if (!this.states) {
      return;
    }
    // get the search keyword
    let search = this.stateFilterCtrl.value;
    if (!search) {
      this.filteredStates.next(this.states.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrys
    this.filteredStates.next(
      this.states.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterCountrys() {
    if (!this.countrys) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountrys.next(this.countrys.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrys
    this.filteredCountrys.next(
      this.countrys.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnInit() {

    this.socioProvider.getAllMembers().then((pessoas: PessoaFiscalDTO[]) => {
      let produtores: PessoaFiscalDTO[] = [];
      this.pessoas = pessoas;
      pessoas.forEach((pessoa) => {
        let produtor = new PessoaFiscalDTO();
        produtor.name = pessoa.name;
        produtor.cpfCNPJ = pessoa.cpfCNPJ;
        produtores.push(produtor);
      });
      this.members = produtores;
      this.membersClone = [...this.members];
    });

    this.imovelForm = this.fb.group({
      nome: ['', Validators.required],
      codigo: ['', Validators.required],
      pais: [''],
      estado: [''],
      municipio: [''],
      codItr: [''],
      currency: ['BRL', Validators.required],
      neighborhood: [''],
      cep: [''],
      street: [''],
      exploracao: ['0', Validators.required],
      terceiros: this.fb.array([])
    });

    this.configCountry();
    this.configState();

    if (this.imovelExistente != undefined) {
      this.explorationProvider.getByProperty(this.imovelExistente.propertyCode).then((exploration: []) => {
        this.convertExplorationToForm(exploration);
      }).catch((result: HttpErrorResponse) => {
        this.snackbar.openLong('Erro ao carregar exploração! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'property'), 'erro');
      });

      this.codImovel = this.imovelExistente.propertyCode;
      Object.assign(this.imovel, this.imovelExistente);
      this.imovel = {...this.imovelExistente};
      this.parseDTO();
      this.editar = true;
    } else {
      this.novoTerceiro();
      this.imovelForm.controls.pais.setValue({id: 32, name: "Brasil", abbreviation: "BR"});
      this.getCountryId();
    }

  }

  convertExplorationToForm(exploration: []) {
    //console.log(exploration)
    exploration.forEach((explorator: ExplorationDTOResponse) => {
      let exploratorForm;
      if (explorator.explorationTypeProd == null) {
        exploratorForm = this.fb.group({
          id: [explorator.id],
          member: [explorator.member.cpfCNPJ, Validators.required],
          caepf: [explorator.caepf],
          participaRateio: [explorator.apportionment],
          ie: [explorator.ie],
          percentage: [explorator.percentage, Validators.required],
          explorationTypeProd: this.imovelForm.controls.exploracao.value === '0' ? [explorator.explorationTypeProd]
                                                                                 : [explorator.explorationTypeProd, Validators.required]
        });
      } else {
        exploratorForm = this.fb.group({
          id: [explorator.id],
          member: [explorator.member.cpfCNPJ, Validators.required],
          caepf: [explorator.caepf],
          participaRateio: [explorator.apportionment],
          ie: [explorator.ie],
          percentage: [explorator.percentage, Validators.required],
          explorationTypeProd: [(ExplorationTypeProd[explorator.explorationTypeProd].toString()), Validators.required]
        });
      }
      this.terceiros.push(exploratorForm);
    });
  }

  editProdutor(member) {
    this.findPessoaByCpfCnpj(member).then((pessoa) => {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
      };
      const modalRef = this.modalService.open(CadastroSocioComponent, modalOptions);
      modalRef.componentInstance.socioExistente = pessoa;
      modalRef.result.then((result) => {
        if(result){
          this.socioProvider.getAllMembers().then((pessoas: PessoaFiscalDTO[]) => {
            let produtores: PessoaFiscalDTO[] = [];
            this.pessoas = pessoas;
            pessoas.forEach((pessoa) => {
              let produtor = new PessoaFiscalDTO();
              produtor.name = pessoa.name;
              produtor.cpfCNPJ = pessoa.cpfCNPJ;
              produtores.push(produtor);
            });
            this.members = produtores;
            this.membersClone = [...this.members];
          });
        }
      }, () => {
      });

    });
  }

  findPessoaByCpfCnpj(member){
    return new Promise ((resolve, reject) =>{
      let pessoa = this.pessoas.find((pessoa) => pessoa.cpfCNPJ == member.cpfCNPJ);
      resolve(pessoa);
    })

  }

  parseDTO() {
    //console.log(this.imovel);
    this.imovelForm.controls.nome.setValue(this.imovel.name);
    this.imovelForm.controls.codigo.setValue(this.imovel.propertyCode);
    this.imovelForm.controls.currency.setValue(this.imovel.currency);
    this.imovelForm.controls.pais.setValue(this.imovel.address.countryDTORespComp);
    this.imovelForm.controls.neighborhood.setValue(this.imovel.address.neighborhood);
    this.imovelForm.controls.codItr.setValue(this.imovel.itrCode);
    this.imovelForm.controls.cep.setValue(this.imovel.address.cep);
    this.imovelForm.controls.street.setValue(this.imovel.address.street);
    this.imovelForm.controls.exploracao.setValue(ExplorationType[this.imovel.explorationType].toString());
    this.imovelAux.houseNumber = this.imovel.address.houseNumber;
    this.imovelAux.complement = this.imovel.address.complement;
    this.imovelAux.countryId = this.imovel.address.countryDTORespComp.id;
    if (this.imovelAux.countryId == 32 && this.imovel.address.countryDTORespComp.stateDTORespComp != undefined) {
      if (this.imovel.address.countryDTORespComp.stateDTORespComp.id != undefined) {
        this.estadosMunicipiosUtil.setCity(this.imovel.address.countryDTORespComp.stateDTORespComp.id);
        this.configCity();
        this.imovelForm.controls.estado.setValue(this.imovel.address.countryDTORespComp.stateDTORespComp);
        this.imovelForm.controls.municipio.setValue(this.imovel.address.countryDTORespComp.stateDTORespComp.cityDTORespComp);
      }
    }
   //console.log(this.imovelForm);
  }

  newTerceiro(): FormGroup {
    if(this.imovelForm.controls.exploracao.value === '0') {
      return this.fb.group({
        id: [undefined],
        member: ['', Validators.required],
        caepf: [''],
        participaRateio: [true],
        ie: [''],
        percentage: ['', Validators.required],
        explorationTypeProd: [null]
      });
    } else {
      return this.fb.group({
        id: [undefined],
        member: ['', Validators.required],
        caepf: [''],
        participaRateio: [true],
        ie: [''],
        percentage: ['', Validators.required],
        explorationTypeProd: [null, Validators.required]
      });
    }
  }

  removeTerceiro(i: number, terceiro: any) {
    // @ts-ignore
    if (terceiro.id != undefined && terceiro.id != '') {
      this.terceirosExclusao.push(terceiro);
      this.terceiros.removeAt(i);
    } else {
      this.membersClone.push(terceiro);
      this.terceiros.removeAt(i);
    }
  }

  changeListMembers(terceiroClone: PessoaFiscalDTO, a: number) {
    let membersFormSelected: [] = this.imovelForm.get('terceiros').value;
    if (a == 0 && this.imovelForm.controls.exploracao.value === '0') {
      this.terceiros.controls[a].get('percentage').setValue('100');
    }
    this.i = 0;
    membersFormSelected.forEach((terceiro: any, index) => {
      if (terceiroClone == terceiro.member) {
        this.i++;
      }
      if (this.i > 1) {
        this.snackbar.openLong('Produtor já selecionado!', 'erro');
        const control = <FormArray>this.imovelForm.controls['terceiros'];
        control.controls[a].setValue({id: '', member: '', percentage: '0', caepf: '', ie: '', explorationTypeProd: null});
      }
    });
  }

  onChangeTerceiros() {
    let exploracao = this.imovelForm.controls.exploracao.value;
    let terceiro: any[] = this.imovelForm.get('terceiros').value;
    if (exploracao === '0') {

      this.terceiros.value.forEach((terceiro, index) => {
        if (index != 0) {
          this.terceirosExclusao.push(terceiro);
        }
      });

      this.terceiros.clear();
      this.terceiros.push(this.fb.group({
        id: [terceiro[0].id],
        member: [terceiro[0].member, Validators.required],
        percentage: ['100.00', Validators.required],
        caepf: [''],
        participaRateio: [true],
        ie: [''],
        explorationTypeProd: [null]
      }));
    } else {
      if (terceiro.length == 1) {
        this.terceiros.push(this.newTerceiro());
      }
    }
  }

  novoTerceiro() {
    this.terceiros.push(this.newTerceiro());
  }

  pushAllTerceiros() {
    if (this.imovelForm.get('terceiros').value[0].member != '') {
      let memberCpfCnpj = {...this.imovelForm.get('terceiros').value};
      this.imovelForm.get('terceiros').value.forEach((terceiro) => {
        if (terceiro.id != undefined && terceiro.id != '') {
          this.terceirosExclusao.push(terceiro);
        }
      });
      this.terceiros.clear();
      this.terceiros.push(this.fb.group({
        id: [undefined],
        member: [memberCpfCnpj[0].member, Validators.required],
        percentage: ['50.00', Validators.required],
        caepf: [''],
        participaRateio: [true],
        ie: [''],
        explorationTypeProd: [null, Validators.required]
      }));
      this.members.forEach((pessoa: PessoaFiscalDTO, index) => {
        if (pessoa.cpfCNPJ != memberCpfCnpj[0].member) {
          const terceiro = this.fb.group({
            id: [undefined],
            member: [pessoa.cpfCNPJ, Validators.required],
            percentage: ['0', Validators.required],
            caepf: [''],
            participaRateio: [true],
            ie: [''],
            explorationTypeProd: [null, Validators.required]
          });
          this.terceiros.push(terceiro);
        }
      });
    } else {
      this.snackbar.openLong('Selecione ao menos um produtor para incluir o restante!', 'atencao');
    }
  }

  getCountryId() {
    this.imovelAux.countryId = this.imovelForm.controls.pais.value.id;
  }

  getStateId() {
    this.imovelAux.stateId = this.imovelForm.controls.estado.value.id;
    this.estadosMunicipiosUtil.setCity(this.imovelForm.controls.estado.value.id);
    this.configCity();
  }

  setImovelForm() {
    if (this.imovelAux.countryId == 32) {
      // this.imovelForm.controls.cep.setValidators([Validators.required]);
    } else {
      this.imovelForm.controls.cep.clearValidators();
    }
    this.imovelAux.cep = this.imovelForm.controls.cep.value;
    this.imovelAux.name = this.imovelForm.controls.nome.value;
    this.imovelAux.propertyCode = this.imovelForm.controls.codigo.value;
    this.imovelAux.currency = this.imovelForm.controls.currency.value;
    this.imovelAux.itrCode = this.imovelForm.controls.codItr.value;
    this.imovelAux.neighborhood = this.imovelForm.controls.neighborhood.value;
    this.imovelAux.street = this.imovelForm.controls.street.value;
    this.imovelAux.explorationType = ExplorationType[this.imovelForm.controls.exploracao.value];
    this.imovelAux.countryId = this.imovelForm.controls.pais.value.id;
    this.imovelAux.companyId = +localStorage.getItem('idEmpresa');
    if (this.imovelForm.controls.pais.value.id == 32) {
      this.imovelAux.cityId = this.imovelForm.controls.municipio.value.id;
      this.imovelAux.stateId = this.imovelForm.controls.estado.value.id
    }
    this.imovelExistente != undefined ? this.imovelAux.active = this.imovel.active : this.imovelAux.active = true;
  }

  setEditedObject(imovel: ImovelDTO) {
    this.imovelExistente.propertyCode = imovel.propertyCode;
    this.imovelExistente.name = imovel.name;
    this.imovelExistente.currency = imovel.currency;
    this.imovelExistente.itrCode = imovel.itrCode;
    this.imovelExistente.address = imovel.address;
    this.imovelExistente.explorationType = imovel.explorationType;
  }

  setExploration(imovel: ImovelDTO) {
    this.terceirosExclusao.forEach((terceiro, index) => {
      this.explorationProvider.delExploration(terceiro).then(() => {
      }).catch((result: HttpErrorResponse) => {
        this.snackbar.openLong(this.errorUtil.checkErrorStatus(result, result.status, result.error, 'property'), 'erro');
      });
    });
    let formExploration: any = this.imovelForm.get('terceiros').value;
    let explorersPost: ExplorationDTOResponse[] = [];
    let explorersPut: ExplorationDTOResponse[] = [];
    formExploration.forEach((explorerForm) => {
      let explorer = new ExplorationDTOResponse();
      explorer.cpfCNPJMember = explorerForm.member;
      explorer.companyId = +localStorage.getItem('idEmpresa');
      explorer.propertyCode = imovel.propertyCode;
      explorer.percentage = explorerForm.percentage;
      explorer.apportionment = explorerForm.participaRateio;
      explorer.ie = explorerForm.ie;
      explorer.caepf = explorerForm.caepf;
      explorer.explorationTypeProd = this.imovelForm.controls.exploracao.value === '0' ?
                                          null : ExplorationTypeProd[explorerForm.explorationTypeProd];
      if (explorerForm.id == undefined) {
        explorersPost.push(explorer);
      } else {
        explorer.id = explorerForm.id;
        explorersPut.push(explorer);
      }
      //console.log(explorer);
    });
    this.executePostAndPutData(explorersPost, explorersPut);
  }

  executePostAndPutData(explorersPost, explorersPut) {
    if (explorersPut.length != 0) {
      this.putExplorators(explorersPut);
    }
    if (explorersPost.length != 0) {
      //console.log(explorersPost);
      this.postExplorators(explorersPost);
    }
  }

  checkPercentage(closeModalParameter, imovel, editMode: boolean, underOrBelow: string) {
    return new Promise((accept, reject) => {
      const dialogText: Dialog = new Dialog();
      dialogText.button = 'Continuar mesmo assim';
      dialogText.buttonCancel = 'Cancelar e conferir';
      switch (underOrBelow) {
        case 'under':
          dialogText.header = 'A porcentagem de exploração é INFERIOR a 100%!';
          dialogText.subHeader = 'Os produtores selecionados possuem o total de exploração MENOR que 100%. Essa configuração não é usual e pode acarretar em relatórios incorretos.';
          break;
        case 'below':
          dialogText.header = 'A porcentagem de exploração SUPERIOR a 100%!';
          dialogText.subHeader = 'Os produtores selecionados possuem o total de exploração MAIOR que 100%. Essa configuração não é usual e pode acarretar em relatórios incorretos.';
          break;
      }
      const dialogRef = this.dialog.open(BasicAlertComponent, {
        data: dialogText,
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        if (dialogText.button === result) {
          if(editMode) {
            this.afterPostAndPutImovel(imovel, imovel, editMode);
          }
          accept(true);
        } else {
          this.enviando = false;
          reject(false);
        }
      });
    })
  }

  calculatePercentageTotal(explorators: ExplorationDTOResponse[]): number {
    let percetageTotal: number = 0;
    explorators.forEach((explorator: ExplorationDTOResponse) => {
      percetageTotal = percetageTotal + Math.trunc(explorator.percentage * 100)
    });
    return percetageTotal / 100;
  }

  postExplorators(explorators) {
    //console.log(explorators);
    this.explorationProvider.postExploration(explorators).then((result) => {
      this.snackbar.openLong("Sucesso! Exploração configurada com sucesso!", 'success');
    }).catch((error: HttpErrorResponse) => {
      this.snackbar.openLong("Erro ao configurar a exploração! " + this.errorUtil.checkErrorStatus(error, error.status, error.error, 'property'), 'erro');
    });
  }

  putExplorators(explorators) {
    //console.log(explorators);
    this.explorationProvider.putExploration(explorators).then((result) => {
      this.snackbar.openLong("Sucesso! Exploração atualizada com sucesso!", 'success');
    }).catch((error: HttpErrorResponse) => {
      this.snackbar.openLong("Erro ao atualizar a exploração! " + this.errorUtil.checkErrorStatus(error, error.status, error.error, 'property'), 'erro');
    });
  }

  novoImovel() {
    this.enviando = true;
    this.setImovelForm();
    if (this.imovelForm.valid) {
      this.postProperty(this.imovelAux);
    } else {
      this.enviando = false;
      this.enviado = true;
      //console.log(this.imovelAux);
      //console.log(this.imovelForm);
      this.snackbar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  afterPostAndPutImovel(closeParameter, imovel, editMode: boolean) {
    if (editMode) {
      this.snackbar.openLong("Sucesso! " + imovel.name + " editado com sucesso!", 'success');
      this.setEditedObject(imovel);
      this.activeModal.close(true);
    } else {
      this.snackbar.openLong("Sucesso! " + imovel.name + " inserido com sucesso!", 'success');
      this.snackbar.openLong('Configurando exploração do imóvel...', 'atencao');
    }
    this.setExploration(imovel);
    this.activeModal.close(closeParameter);
  }

  editarImovel() {
    this.enviando = true;
    this.setImovelForm();
    if (this.imovelForm.valid) {
      if (this.codImovel != this.imovelAux.propertyCode) {
        this.imovelAux.newerPropertyCode = this.imovelAux.propertyCode;
        this.imovelAux.propertyCode = this.codImovel;
        this.putProperty(this.imovelAux);
      } else {
        this.putProperty(this.imovelAux);
      }
    } else {
      this.enviado = true;
      this.enviando = false;
      //console.log(this.terceiros);
      this.snackbar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  postProperty(imovel) {
    if (this.calculatePercentageTotal(this.imovelForm.get('terceiros').value) > 100) {
      this.checkPercentage(imovel, imovel, false, "under").then((val) => {
        this.postPropertyAfterCheckPercentage(imovel);
      });
    } else if (this.calculatePercentageTotal(this.imovelForm.get('terceiros').value) < 100) {
      this.checkPercentage(imovel, imovel, false, "under").then((val) => {
        this.postPropertyAfterCheckPercentage(imovel);
      });
    } else {
      this.postPropertyAfterCheckPercentage(imovel);
    }
  }

  postPropertyAfterCheckPercentage(imovel) {
    this.propertyProvider.postProperty(imovel).then((imovelPost: ImovelDTO) => {
      this.afterPostAndPutImovel(imovelPost, imovelPost, false);
    }).catch((error: HttpErrorResponse) => {
      console.error(error.error.error_description);
      this.enviando = false;
      if(error.error.error_description.includes("ukibpe6rme2hhf2a1megebj0gpk")) {
        this.snackbar.openLong("Nome de imóvel já existente! " + this.errorUtil.checkErrorStatus(error, error.status, error.error, 'property'), 'erro');
      } else {
        this.snackbar.openLong("Erro ao inserir imóvel! " + this.errorUtil.checkErrorStatus(error, error.status, error.error, 'property'), 'erro');
      }
    });
  }

  putProperty(property) {
    this.propertyProvider.putProperty(property).then((imovelPut: ImovelDTO) => {
      if (this.calculatePercentageTotal(this.imovelForm.get('terceiros').value) > 100) {
        this.checkPercentage(this.municipioId, imovelPut, true, "below");
      } else if (this.calculatePercentageTotal(this.imovelForm.get('terceiros').value) < 100) {
        this.checkPercentage(this.municipioId, imovelPut, true, "under");
      } else {
        this.afterPostAndPutImovel(this.municipioId, imovelPut, true);
      }
    }).catch((error: HttpErrorResponse) => {
      this.enviando = false;
      this.snackbar.openLong(this.errorUtil.checkErrorStatus(error, error.status, error.error, 'property'), 'erro');
    });
  }

  abrirCadastroSocio() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroSocioComponent, modalOptions);
    modalRef.result.then((result) => {
      this.members.push(result);
    }, () => {
    });
  }

  protected setInitialValue() {

    this.filteredCountrys
      .pipe(take(1), takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.countrySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
      });

    if (this.editar) {

      this.filteredStates
        .pipe(take(1), takeUntil(this._onDestroyState))
        .subscribe(() => {
          this.stateSelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

      this.filteredCities
        .pipe(take(1), takeUntil(this._onDestroyCity))
        .subscribe(() => {
          this.citySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

    }

  }


}
