import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ValueValidator} from "../../../../util/customValidators/valueValidator";
import * as moment from "moment";
import {map, startWith, takeUntil} from "rxjs/operators";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {ParticipantProvider} from "../../../../providers/participant/participant";
import {BankAccountProvider} from "../../../../providers/company/bank_account/bank_account";
import {Snackbar} from "../../../../util/snackbar";
import {LivroCaixa} from "../../../../util/livroCaixa";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {ContaBancariaDTO} from "../../../../model/dto/ContaBancariaDTO";
import {ParticipantDTOResponse} from "../../../../model/dto/ParticipantDTOResponse";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {ConsultaDfe, ConsultaDfe2} from "../../../../model/ConsultaDfe";
import {Duplicatas} from "../../../../model/Duplicatas";
import {AccountProvider} from "../../../../providers/account/account";
import {
  CadastroContaBancariaComponent
} from "../../minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component";
import {CadastroImovelComponent} from "../../minhasPropriedades/cadastro-imovel/cadastro-imovel.component";
import {
  CadastroPlanoDeContasComponent
} from "../../cadastros/plano-de-contas/cadastro-plano-de-contas/cadastro-plano-de-contas.component";
import {AccountPlanDTOResponse} from "../../../../model/dto/AccountPlanDTOResponse";
import {GrouperDTOResponse} from "../../../../model/dto/GrouperDTOResponse";
import {ReleaseType} from "../../../../model/enum/ReleaseType";
import {HttpErrorResponse} from "@angular/common/http";
import {PostingProvider} from "../../../../providers/bookkeeping/posting/posting";
import {ErrorUtil} from "../../../../util/error";
import {PagamentoPlanejadoComponent} from "./pagamento-planejado/pagamento-planejado.component";
import {DataTrabalhoDialog} from "../data-trabalho-dialog/data-trabalho-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {HistoricDTOResponse} from "../../../../model/dto/HistoricDTOResponse";
import {CadastroHistoricoComponent} from "../../cadastros/historicos/cadastro-historico/cadastro-historico.component";
import {DateUtil} from "../../../../util/dateUtil";
import {ConvertObjectPipe} from "../../../../util/pipes/ConvertObjectPipe";
import {Dialog} from "../../../../model/Dialog";
import {BasicAlertComponent} from "../../../shared/alert/basic-alert/basic-alert.component";
import {DateLockProvider} from 'src/providers/bookkeeping/date-lock/date-lock';
import Swal from 'sweetalert2'
import {DateLock} from 'src/model/dto/bookkeping/DateLock';
import {CheckPermission} from 'src/service/checkPermission';
import {GrouperProvider} from 'src/providers/grouper/grouper';
import {ReceiptProvider} from 'src/providers/receipt/receipt';
import {MatAutocompleteSelectedEvent} from '@angular/material';
import {
  AdicionarArquivosComponent
} from '../../cadastros/enviar-arquivos/adicionar-arquivos/adicionar-arquivos.component';
import {
  CadastroAgrupadoresComponent
} from '../../cadastros/agrupadores/cadastro-agrupadores/cadastro-agrupadores.component';
import {DfeProvider} from '../../../../providers/dfe/dfe';
import {ConsultaPdfXml} from '../../../../providers/dfe/consultaPdfXml';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ContaMovimentoIndividualComponent} from './conta-movimento-individual/conta-movimento-individual.component';
import {ContaIndividualComponent} from './conta-individual/conta-individual.component';
import {ImovelIndividualComponent} from './imovel-individual/imovel-individual.component';
import {historicoUtil} from 'src/util/historico';
import {HistoricoIndividualComponent} from './historico-individual/historico-individual.component';
import {ProdutosEstoqueComponent} from "./produtos-estoque/produtos-estoque.component";

@Component({
  selector: 'app-cadastrar-lancamento-dup',
  templateUrl: './cadastrar-lancamento-dup.component.html',
  styleUrls: ['./cadastrar-lancamento-dup.component.scss']
})

export class CadastrarLancamentoDupComponent implements OnInit {

  @Input() public documentosExistentes: any[];
  @Input() public tipoDocumento: 'duplicata' | 'dfe';
  @Input() public categoriasCache: AccountPlanDTOResponse[];
  @Input() public imoveisCache: ImovelDTO[];
  @Input() public contasCache: ContaBancariaDTO[];
  @Input() public historicosCache: HistoricDTOResponse[];
  @Input() public participantesCache: ParticipantDTOResponse[];

  @ViewChild('grouperInput', {static: false}) grouperInput: ElementRef<HTMLInputElement>;
  @ViewChild('receiptInput', {static: false}) receiptInput: ElementRef<HTMLInputElement>;
  @ViewChild('historicInput', {static: false}) historicInput: ElementRef<HTMLInputElement>;


  public lancamentoForm: FormGroup;

  public imoveis: ImovelDTO[];
  public contas: ContaBancariaDTO[];
  public categorias: AccountPlanDTOResponse[];
  public historicos: HistoricDTOResponse[];
  public participantes: ParticipantDTOResponse[];

  enviado: boolean = false;
  carregando: boolean = false;
  changeMade: boolean = false;
  displayedColumns: string[] = ['select', 'id', 'data', 'valor', 'button'];
  selection = new SelectionModel<any>(true, []);
  valorTotal: number;
  descontoProporcionalAtivo: boolean = false;
  valorProporcional: string;
  saleDfe: boolean;
  bookkeepingDuplicateDate: boolean;

  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  public filter_bankAccount: ReplaySubject<ContaBancariaDTO[]> = new ReplaySubject<ContaBancariaDTO[]>(1);
  public filter_planAccount: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filter_historic: ReplaySubject<HistoricDTOResponse[]> = new ReplaySubject<HistoricDTOResponse[]>(1);

  public propertyFilterCtrl: FormControl = new FormControl();
  public accountPlanFilterCtrl: FormControl = new FormControl();
  public bankAccountFilterCtrl: FormControl = new FormControl();
  public historicFilterCtrl: FormControl = new FormControl();
  public toogleAll = false
  protected _onDestroy = new Subject<void>();

  public locks: DateLock[] = [];

  public groupers: any = [];
  public allGroupers: any[];
  public filteredGroupers: GrouperDTOResponse[];
  public filteredGrouper: Observable<GrouperDTOResponse[]>;
  public grouperCtrl = new FormControl();
  public agrupadores: GrouperDTOResponse[];

  public receipts: any = [];
  public allReceipts: any[];
  public filteredReceipts: any[]
  public filteredReceipt: Observable<any[]>;
  public receiptCtrl = new FormControl();

  public loadingHistoric: boolean = false;
  public loadingAccount: boolean = false;

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public individualHistoricForm;
  public modelHistoricForm;

  constructor(public activeModal: NgbActiveModal,
              private accountProvider: AccountProvider,
              public historicoUtil: historicoUtil,
              private participantesProvider: ParticipantProvider,
              public consultaPdfXml: ConsultaPdfXml,
              public dateUtil: DateUtil,
              public errorUtil: ErrorUtil,
              public dialog: MatDialog,
              public fb: FormBuilder,
              private postingProvider: PostingProvider,
              public snackbar: Snackbar,
              public dfeProvider: DfeProvider,
              public livroCaixa: LivroCaixa,
              public modalService: NgbModal,
              public dateLockProvider: DateLockProvider,
              public checkPermission: CheckPermission,
              public grouperProvider: GrouperProvider,
              public receiptProvider: ReceiptProvider,
              private snackBar: Snackbar,
              public adicionarArquivosModalService: NgbModal,
              private formBuilder: FormBuilder,
              public propertyProvider: PropertyProvider,
              public bankAccountProvider: BankAccountProvider,) {

  }

  ngOnInit() {
    this.initializeParticipant();
    this.initializeDataForm();
    this.configuracaoInicialForm();
    this.setFirstDuplicatesSelected();
    this.calculaValorTotal();
    this.getDateLocks();
  }

  selectedHistoric(event, historicoUtil, form): void {
    historicoUtil.historics.push(event.option.viewValue);
    historicoUtil.historicCtrl.setValue(null);
    let re = new RegExp('#', "g");
    form.controls.modelo.setValue(form.value.modelo.replace(re, ''))
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeDataForm() {
    this.imoveis = this.imoveisCache;
    this.categorias = this.categoriasCache;
    this.contas = this.contasCache;
    this.historicos = this.sortHistorics(this.historicosCache);
    this.participantes = this.participantesCache;
    this.initializePropertySearch();
    this.initializeAccountPlanSearch();
    this.initializeBankAccountSearch();
    this.initializeHistoricSearch();
    this.initializeIndividualHistoricForm();

    this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
      this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
        this.allGroupers = groupers;
        this.agrupadores = groupers;
        this.filteredGroupers = this.agrupadores;

        this.allReceipts = receipts;
        this.allReceipts.forEach((receipt) => {
          receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
        })
        this.filteredReceipts = this.allReceipts;
        this.initializeSearchSelect();
      });
    });
  }

  selectedGrouper(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value != 0) {
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.groupers.forEach((grouper, index: number) => {
        if (grouper.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Agrupador já selecionado!', 'atencao');
        }
        if (!exist && this.groupers.length === index + 1) {
          this.groupers.push(eventInsert);
          this.grouperInput.nativeElement.value = '';
          this.grouperCtrl.setValue(null);
        }
      });
      if (this.groupers.length === 0) {
        this.groupers.push(eventInsert);
        this.grouperInput.nativeElement.value = '';
        this.grouperCtrl.setValue(null);
      }
    }
  }

  removeGrouper(indx): void {
    this.groupers.splice(indx, 1);
  }

  selectedReceipt(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.receipts.forEach((receipt, index: number) => {
        if (receipt.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Arquivo já selecionado!', 'atencao');
        }
        if (!exist && this.receipts.length === index + 1) {
          this.receipts.push(eventInsert);
          this.receiptInput.nativeElement.value = '';
          this.receiptCtrl.setValue(null);
        }
      });
      if (this.receipts.length === 0) {
        this.receipts.push(eventInsert);
        this.receiptInput.nativeElement.value = '';
        this.receiptCtrl.setValue(null);
      }
    }
  }

  initializeParticipant() {
    let searchArray = [];
    this.documentosExistentes.forEach((element, i) => {
      element.emitente['accountId'] = null;
      element.emitente['historicId'] = null;
      element.destinatario['accountId'] = null;
      element.destinatario['historicId'] = null;
      searchArray.push(element.destinatario.cpf)
      searchArray.push(element.emitente.cpf)
    });


    this.participantesProvider.getAccountsHistoricsByParticipants(searchArray).then((result) => {
      this.documentosExistentes.forEach((element, i) => {
        if (result.hasOwnProperty(element.emitente.cpf)) {
          element.emitente['accountId'] = result[element.emitente.cpf][1];
          element.emitente['historicId'] = result[element.emitente.cpf][2];
          this.filter_planAccount.forEach((filter_planAccount) => {
            filter_planAccount.forEach((account) => {
              if (account.id ==  element.emitente['accountId'])
                this.lancamentoForm.controls.categoria.setValue(account);
            })
          });
          this.filter_historic.forEach((filter_historic) => {
            filter_historic.forEach((historic) => {
              if (historic.id == element.emitente['historicId']) {
                this.lancamentoForm.controls.historico.setValue(historic);
                this.setIndividualHistorics(historic);
              }
            })
          });
        }
        if (result.hasOwnProperty(element.destinatario.cpf)) {
          element.destinatario['accountId'] = result[element.destinatario.cpf][1];
          element.destinatario['historicId'] = result[element.destinatario.cpf][2];
        }
      });
    }).catch((error) => {
      console.log("error", error);
    })
  }

  changeParticipant(value) {
    const accountId = value.checked
      ? this.documentosExistentes[0].destinatario.accountId
      : this.documentosExistentes[0].emitente.accountId;

    if (accountId) {
      this.selectPlanoConta({id: accountId}, true);
    }
    this.syncHistorico();
  }

  initializeIndividualHistoricForm() {
    this.individualHistoricForm = new FormArray([]);
    this.modelHistoricForm = new FormArray([]);
    this.documentosExistentes.forEach((element: ConsultaDfe, index) => {
      element.historicoUtil = new historicoUtil
      this.individualHistoricForm.push(this.formBuilder.group({
        historico: [""],
        modelo: [""],
      }))
    })
    this.setIndividualHistorics('DEFAULT');
  }

  teste($event, provider) {
    let re = new RegExp('#', "g");
    $event = $event.replace(re, '')
    provider.historics = $event.split(' ')
    this.syncHistorico()
  }

  setIndividualHistorics(value) {
    const model = value === 'DEFAULT' ? 'LANÇAMENTO @participante@ DF-e @numero-documento@' : value.model;
    const re = /#/g;

    this.documentosExistentes.forEach((element, i) => {
      const cleanedModel = model.replace(re, '');
      this.individualHistoricForm.controls[i].controls.modelo.setValue(cleanedModel);
      element.historicoUtil.historics = model.split('#').filter(str => str.trim());

      if (element.pagamentoPlanejado) {
        element.pagamentoPlanejado.forEach((element2: any) => {
          element2.historic = {
            historico: this.convertHistoricModelToString(model, element, element2),
            modelo: cleanedModel
          };
        });
      }
    });
    this.syncHistorico();
  }

  syncHistorico() {
    this.individualHistoricForm.controls.forEach((historicForm, index) => {
      const doc = this.documentosExistentes[index];
      const historic = this.convertHistoricModelToString(doc.historicoUtil.genereteHistoric(), doc);
      if (doc.pagamentoPlanejado) {
        doc.pagamentoPlanejado.forEach((element: any) => {
          const historicUtil = new historicoUtil();
          historicUtil.historics = element.historic.modelo.split(' ');
          element.historic = {
            historico: this.convertHistoricModelToString(historicUtil.genereteHistoric(), doc, element),
            modelo: element.historic.modelo
          };
        });
      }
      historicForm.controls.historico.setValue(historic.trim());
    });
  }

  convertHistoricModelToString(model, doc, pagamentPlanejado?) {
    return model.split("#").map(split =>
      split.includes('@') ? this.getIndividualHistoricTag(split, doc, pagamentPlanejado) : split
    ).join(' ').trim();
  }

  getIndividualHistoricTag(tag, doc, pagamentPlanejado) {
    const getParticipant = () => this.saleDfe ? doc.destinatario : doc.emitente;
    const getMonth = (monthsToSubtract = 0) => moment(this.lancamentoForm.get('dataPagamento').value).subtract(monthsToSubtract, 'month').locale('pt-br').format('MMM').toUpperCase();
    const getYear = (yearsToSubtract = 0) => moment(this.lancamentoForm.get('dataPagamento').value).subtract(yearsToSubtract, 'year').format('Y');
    switch (tag) {
      case '@participante@':
        return getParticipant().nome.toUpperCase();
      case '@participante-CpfCnpj@':
        return getParticipant().cpf || getParticipant().cnpj;
      case '@numero-documento@':
        return doc.numeroDoc;
      case '@conta@':
        if (pagamentPlanejado && pagamentPlanejado.accountsChart) {
          return pagamentPlanejado.accountsChart.description.split('- ')[1];
        }
        const contaSplit = this.lancamentoForm.get('categoria').value.description ? this.lancamentoForm.get('categoria').value.description.split('-') : [];
        return contaSplit[1] || ' ';
      case '@mes@':
        return getMonth();
      case '@ano@':
        return getYear();
      case '@ano-anterior@':
        return getYear(1);
      case '@mes-anterior@':
        return getMonth(1);
      case '@mes-e-ano-anterior@':
        return `${getMonth(1)} ${getYear(1)}`;
      default:
        return tag;
    }
  }

  selectPlanoConta(value, usingParticipant) {
    this.loadingAccount = true;
    this.accountProvider.getAccountById(value.id).then((contaResult: any) => {
      if (contaResult) {
        this.filter_planAccount.forEach((filter_planAccount) => {
          filter_planAccount.forEach((account) => {
            if (account.id == contaResult.id)
              this.lancamentoForm.controls.categoria.setValue(account);

          })
        });
        this.loadingAccount = false;
        if (contaResult.historicId) {
          this.filter_historic.forEach((filter_historic) => {
            filter_historic.forEach((historic) => {
              if (historic.id == contaResult.historicId) {
                this.lancamentoForm.controls.historico.setValue(historic);
                this.setIndividualHistorics(historic);
                usingParticipant ? this.snackbar.openLong("Selecionados conta resultado e histórico associado ao participante", 'success') : this.snackbar.openLong("Selecionado histórico associado a conta resultado", 'success');
              }
            })
          });
        }
        else {
          this.syncHistorico();
        }
      }
      else {
        this.loadingAccount = false;
      }
    }).catch((error) => {
      this.loadingAccount = false;
    });
    this.syncHistorico()
  }

  selectPlanoContaEachDuplicata(participant, usingParticipant, element) {
    if (participant.accountId) {
      this.filter_planAccount.forEach(filter_planAccount => {
        filter_planAccount.forEach(account => {
          if (account.id === participant.accountId) {
            element.pagamentoPlanejado.forEach(element2 => {
              element2.accountsChart = account;
              element2.accountsChartId = account.id;
            });
            if (participant.historicId) {
              this.filter_historic.forEach(filter_historic => {
                filter_historic.forEach(historic => {
                  if (historic.id === participant.historicId) {
                    const re = /#/g;
                    element.pagamentoPlanejado.forEach(element2 => {
                      element2.historic = {
                        historico: this.convertHistoricModelToString(historic.model, element, element2),
                        modelo: historic.model.replace(re, '')
                      };
                    });
                    this.syncHistorico();
                    this.snackbar.openLong(usingParticipant ? "Selecionados conta resultado e histórico associado ao participante" : "Selecionado histórico associado a conta resultado", 'success');
                  }
                });
              });
            } else {
              this.syncHistorico();
              if (usingParticipant) this.snackbar.openLong("Selecionada conta resultado associada ao participante", 'success');
            }
          }
        });
      });
      this.syncHistorico();
    }
    this.syncHistorico();
  }

  removeReceipt(indx): void {
    this.receipts.splice(indx, 1);
  }

  getFile(receipt) {
    this.carregando = true;
    this.receiptProvider.downloadFile(receipt.file).then((value: any) => {
      const extension = receipt.file.split('.').pop();
      let blob;
      if (extension == 'pdf' || extension == 'xls' || extension == 'xlsx' || extension == 'doc' || extension == 'docx' || extension == 'txt') {
        let type = 'application/' + extension;
        blob = new Blob([value], {type: type});
      } else if (extension == 'png' || extension == 'jpg' || extension == 'jpeg') {
        let type = 'image/' + extension;
        blob = new Blob([value], {type: type});
      } else {
        blob = new Blob([value], {type: 'application/octet-stream'});
      }
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.carregando = false;
    }).catch((error) => {
      this.snackBar.openLong('Erro ao baixar arquivo', 'error');
    });

  }

  openFileModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.adicionarArquivosModalService.open(AdicionarArquivosComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result !== 'Cancelou') {
        this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
          this.allReceipts = receipts;
          this.allReceipts.forEach((receipt) => {
            receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
          })
          this.filteredReceipts = this.allReceipts;
          this.receiptCtrl.setValue('');
          if (result.lenght != 0) {
            result.forEach((receipt) => {
              this.receipts.push(receipt)
            });
          }
        });
      }
    });
  }

  openAgrupadorModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroAgrupadoresComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result !== 'Cancelou') {
        this.grouperProvider.getAllGroupers().then((groupers: any[]) => {
          this.allGroupers = groupers;
          this.groupers.push(result);
          this.filteredGroupers = this.allGroupers;
          this.grouperCtrl.setValue('');
        });
      }
    });
  }

  openHistoricoIndividualModal(index, element) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(HistoricoIndividualComponent, modalOptions);
    modalRef.componentInstance.filter_historic = this.filter_historic
    modalRef.componentInstance.doc = this.documentosExistentes[index]
    modalRef.componentInstance.lancamentoForm = this.lancamentoForm
    modalRef.componentInstance.saleDfe = this.saleDfe
    modalRef.componentInstance.eidtable = element.historic
    modalRef.componentInstance.duplicate = element;
    modalRef.result.then((result: any) => {
      element.historic = result
    });
  }

  sortHistorics(historicos) {
    return historicos.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  initializeAccountPlanSearch() {
    this.filter_planAccount.next(this.categorias.slice());
    this.accountPlanFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAccountPlan();
      });
  }

  initializePropertySearch() {
    this.filter_property.next(this.imoveis.slice());
    this.propertyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProperties();
      });
  }

  initializeHistoricSearch() {
    this.filter_historic.next(this.historicos.slice());
    this.historicFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterHistoric();
      });
  }

  initializeBankAccountSearch() {
    this.filter_bankAccount.next(this.contas.slice());
    this.bankAccountFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBankAccount();
      });
  }

  initializeSearchSelect() {
    this.filteredGrouper = this.grouperCtrl.valueChanges.pipe(
      startWith(''),
      map((fruit: string | null) => fruit ? this._filterG(fruit) : this.allGroupers.slice()));

    this.filteredReceipt = this.receiptCtrl.valueChanges.pipe(
      startWith(''),
      map((fruit: string | null) => fruit ? this._filterR(fruit) : this.allReceipts.slice()));

    this.initializeAccountPlanSearch();
    this.initializePropertySearch();
    this.initializeHistoricSearch();
    this.initializeBankAccountSearch();
  }

  private _filterG(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allGroupers.filter(fruit => fruit.name.toLowerCase().includes(name.toLowerCase())) : this.allGroupers;
    }
  }

  private _filterR(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allReceipts.filter(fruit => fruit.description.toLowerCase().includes(name.toLowerCase())) : this.allReceipts;
    }
  }

  convertPropertyObjectSearchSelect(imoveis) {
    let newImovel;
    if (Array.isArray(imoveis)) {
      let newImovelArray: ImovelDTO[] = [];
      newImovel = newImovelArray;
      imoveis.forEach((imovel) => {
        let imovelMod: any = {
          currency: imovel.currency,
          explorationType: imovel.explorationType,
          itrCode: imovel.itrCode,
          name: imovel.propertyCode + ' - ' + imovel.name,
          propertyCode: imovel.propertyCode,
        }
        newImovel.push(imovelMod);
      });
    } else {
      imoveis.name = imoveis.propertyCode + ' - ' + imoveis.name
      newImovel = imoveis;
    }
    return newImovel;
  }

  convertBankAccountObjectSearchSelect(contas) {
    if (Array.isArray(contas)) {
      contas.forEach((contas) => {
        let name = contas.description;
        contas.description = contas.code + ' - ' + name;
      });
    } else {
      let name = contas.description;
      contas.description = contas.code + ' - ' + name;
    }
    return contas;
  }

  configuracaoInicialForm() {
    this.lancamentoForm = this.fb.group({
      dataPagamento: [moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format("YYYY-MM-DD"), Validators.required],
      imovel: [this.imoveis.find(obj => obj.default), Validators.compose([Validators.required])],
      valorTotal: ['0', Validators.compose([ValueValidator, Validators.required])],
      categoria: ['', Validators.compose([ValueValidator, Validators.required])],
      contabancaria: this.livroCaixa.getTipo() == 1 ?
        [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : '', Validators.compose([Validators.required])] :
        [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : ''],
      historico: ['DEFAULT'],
      lancamentos: [this.selection.selected, Validators.compose([LengthValidator])]
    });
    if (+this.livroCaixa.getTipo() == 1) {
      this.lancamentoForm.controls.contabancaria.setValidators(Validators.compose([Validators.required]));
    }
    moment.locale("pt-br");
  }

  calculaValorTotal() {
    if (this.selection.selected.length != 0) {
      this.descontoProporcionalAtivo = false;
      let valorTotal: number = 0;
      let idController = []
      this.selection.selected.forEach((dfe: any) => {
        if (dfe.escriturada != true && idController.findIndex(val => val === dfe.id) != 0) {
          valorTotal = +dfe.valor + valorTotal;
          idController.push(dfe.id)
        }
      });
      this.lancamentoForm.controls.dataPagamento.setValue(moment(this.dateUtil.removeTimeZone(this.selection.selected[0].vencimento)));
      this.valorTotal = 0;
      this.valorTotal = valorTotal;
      this.lancamentoForm.controls.valorTotal.setValue(valorTotal);
    } else {
      this.valorTotal = 0;
      this.lancamentoForm.controls.valorTotal.setValue(this.valorTotal);

    }
  }

  calculaValorProporcional() {
    if ((this.lancamentoForm.controls.valorTotal.value / this.valorTotal != 1) && this.selection.selected.length != 0) {
      this.descontoProporcionalAtivo = true;
      this.valorProporcional = (+this.lancamentoForm.controls.valorTotal.value / this.valorTotal).toString();
    } else {
      this.descontoProporcionalAtivo = false;
    }
  }

  marcarTodasDuplicatas(c) {
    this.documentosExistentes.forEach((element: ConsultaDfe) => {
      element.pagamentoPlanejado.filter((subElement: Duplicatas) => !subElement.escriturada).forEach((subElement: Duplicatas) => {
        if (c.checked && !this.selection.isSelected(subElement)) {
          this.selection.select(subElement)
        } else if (!c.checked && this.selection.isSelected(subElement)) {
          this.selection.deselect(subElement);
        }
      })
    });
    this.calculaValorTotal();
  }


  masterToggle(documento) {
    this.toogleAll = !this.toogleAll;
    documento.pagamentoPlanejado.forEach(element => {
      if (element.escriturada == false) {
        this.selection.toggle(element);
      }
    });
    this.calculaValorTotal();
  }

  checkboxLabel(row?: ConsultaDfe): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  returnDataForTable(duplicatas: Duplicatas[]): MatTableDataSource<Duplicatas> {
    duplicatas.sort(function (a, b) {
      return (a.vencimento) < (b.vencimento) ? -1 : (a.vencimento) > (b.vencimento) ? 1 : 0;
    });
    return new MatTableDataSource(duplicatas);
  }


  newOptionSelect(type: string) {
    switch (type) {
      case "account":
        this.openModalNewOption(CadastroContaBancariaComponent, this.contas, 'contabancaria');
        break;
      case "property":
        this.openModalNewOption(CadastroImovelComponent, this.imoveis, 'imovel');
        break;
      case "accountPlan":
        this.openModalNewOption(CadastroPlanoDeContasComponent, this.categorias, 'categoria');
        break;
      case "historic":
        this.openModalNewOption(CadastroHistoricoComponent, this.historicos, 'historico');
        break;
      default:
        break;
    }
  }

  openModalContaMovimento(x) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') === 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ContaMovimentoIndividualComponent, modalOptions);
    modalRef.componentInstance.bankAccount = this.contas;
    if (x.bankAccount) {
      modalRef.componentInstance.edit = x.bankAccount;
    }
    modalRef.result.then((result) => {
      x.bankAccountId = result !== "clear" ? result.id : null;
      x.bankAccount = result !== "clear" ? result : null;
    }).catch(console.error);
  }

  openModalConta(x) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') === 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ContaIndividualComponent, modalOptions);
    modalRef.componentInstance.planAccount = this.categorias;
    if (x.accountsChartId) {
      modalRef.componentInstance.edit = x.accountsChart;
    }
    modalRef.result.then((result) => {
      x.accountsChartId = result !== "clear" ? result.id : null;
      x.accountsChart = result !== "clear" ? result : null;
      this.documentosExistentes.forEach(doc => {
        const index = doc.pagamentoPlanejado.findIndex(y => y.id === x.id);
        if (index !== -1) {
          const historicUtil = new historicoUtil();
          historicUtil.historics = doc.pagamentoPlanejado[index].historic.modelo.split(' ');
          doc.pagamentoPlanejado[index].historic = {
            historico: this.convertHistoricModelToString(historicUtil.genereteHistoric(), doc, doc.pagamentoPlanejado[index]),
            modelo: doc.pagamentoPlanejado[index].historic.modelo
          };
        }
      });
    }).catch(console.error);
  }

  openModalImovel(x) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') === 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ImovelIndividualComponent, modalOptions);
    modalRef.componentInstance.imovel = this.imoveis;
    if (x.propertyCode) {
      modalRef.componentInstance.edit = x.imovel;
    }
    modalRef.result.then((result) => {
      x.propertyCode = result !== "clear" ? result.propertyCode : null;
      x.imovel = result !== "clear" ? result : null;
    }).catch(console.error);
  }

  openModalNewOption(modalName, list, filtered) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') === 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(modalName, modalOptions);
    modalRef.result.then((result) => {
      if (['contabancaria', 'historico'].includes(filtered)) {
        list.push(this.convertBankAccountObjectSearchSelect(result));
      } else if (filtered === 'imovel') {
        list.push(this.convertPropertyObjectSearchSelect(result));
      } else {
        list.push(result);
      }
      this.initializeSearchSelect();
      this.lancamentoForm.get(filtered).setValue(result);
    }).catch(() => {
    });
  }

  inserirLancamento(button) {
    button.disabled = true;
    this.lancamentoForm.controls.lancamentos.setValue(this.selection.selected);
    this.enviado = true;

    if (this.isLocked()) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' +
          (this.checkPermission.isAccountantAdmin() ?
            'Remova o bloqueio para poder cadastrar um lançamento nesta data.' :
            'Contate um administrador para que o bloqueio seja removido.'),
        icon: 'error'
      }).then(() => {
        button.disabled = false;
      });
    } else if (this.lancamentoForm.valid) {
      this.checkWorkDate(this.lancamentoForm.controls.dataPagamento, button);
    } else {
      button.disabled = false;
      this.snackbar.openLong('Há erros no formulário! Corrija antes de enviar novamente.', 'erro');
    }

  }

  hasHistoric() {
    if (this.historicFilterCtrl.value == null) {
      return true;
    }
    return 'HISTÓRICO PADRÃO FARMCONT'.toLowerCase().includes(this.historicFilterCtrl.value.toLowerCase());
  }

  editProperty(row: any) {
    this.carregando = true;
    this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
      let property = imoveis.find((imovel) => imovel.propertyCode == row.propertyCode);
      this.carregando = false;
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
      };
      const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
      modalRef.componentInstance.imovelExistente = property;
      modalRef.result.then((result) => {
        if (result) {
          this.carregando = true;
          this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
            let pipe = new ConvertObjectPipe();
            this.imoveis = pipe.transform(imoveis, 'imoveis');
            this.initializeSearchSelect();
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            console.log(result);
            this.carregando = false;
          });
        }
      }, () => {
      });
    }).catch((result: HttpErrorResponse) => {
      console.log(result);
      this.carregando = false;
    });
  }


  editContaMovimento(contaMovimento) {
    contaMovimento.description = contaMovimento.description.split(' - ')[1];
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroContaBancariaComponent, modalOptions);
    modalRef.componentInstance.contaExistente = contaMovimento;
    modalRef.result.then((result) => {
      if (result) {
        this.carregando = true;
        this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
          let pipe = new ConvertObjectPipe();
          this.contas = pipe.transform(contas, 'contasBancarias');
          this.initializeSearchSelect();
          this.carregando = false;
        });
      }
    }, () => {
    }).catch((result: HttpErrorResponse) => {
      console.log(result);
      this.carregando = false;
    });
  }

  editContaResultado(contaResultado) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPlanoDeContasComponent, modalOptions);
    contaResultado.name = contaResultado.description.split(' - ')[1]
    modalRef.componentInstance.node = contaResultado;
    modalRef.componentInstance.editar = true;
    modalRef.componentInstance.root = false;
    modalRef.componentInstance.customIds = [contaResultado.customId];
    modalRef.componentInstance.descriptions = [contaResultado.description.split(' -')[1]];
    modalRef.componentInstance.escrituracao = true;
    modalRef.result.then((result) => {
      if (result) {
        this.carregando = true;
        this.accountProvider.getAllAccountsLeafs().then((categorias: AccountPlanDTOResponse[]) => {
          let pipe = new ConvertObjectPipe();
          this.categorias = pipe.transform(categorias, 'categoria');
          this.initializeAccountPlanSearch()
          this.carregando = false;
        });
      }
    }, () => {
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  postNewDocuments(button) {
    const docs = [];
    const docsWillNotBookkepping = [];

    this.documentosExistentes.forEach((dfe: any) => {
      const lancamentos = [];

      this.selection.selected.forEach((dupSelect) => {
        dfe.pagamentoPlanejado.forEach((dup: Duplicatas) => {
          if (dup.id === dupSelect.id) lancamentos.push(dupSelect);
        });
      });

      const pessoaID = this.saleDfe
        ? this.participantes.find(participante => participante.cpfCNPJParticipant === dfe.destinatario.cnpj)
        : this.participantes.find(participante => participante.cpfCNPJParticipant === dfe.emitente.cnpj);

      const dfePost = {
        dfeId: dfe.parcelaId || dfe.id,
        lancamentos,
        pessoaID,
        docId: null,
        numeroDfe: dfe.numeroDoc,
        propertyCode: dfe.propertyCode,
      };

      if (lancamentos.length && pessoaID) {
        docs.push(dfePost);
      } else if (lancamentos.length) {
        dfePost.pessoaID = this.saleDfe ? dfe.destinatario : dfe.emitente;
        docsWillNotBookkepping.push(dfePost);
      }
    });

    if (docs.length && !docsWillNotBookkepping.length) {
      this.postObject(docs, button);
    } else {
      this.docsNotBookkeppingDialog(docs,  button, docsWillNotBookkepping);
    }
  }

  docsNotBookkeppingDialog(docs, button, docsNotBookkepping) {
    const dialogText = new Dialog();
    dialogText.button = 'Escriturar e ignorar esses lançamentos';
    dialogText.header = 'Documentos com participantes sem CPF/CNPJ';
    dialogText.buttonCancel = 'Cancelar';
    dialogText.subHeader = `O participante de ${docsNotBookkepping.length} duplicata(s) selecionada(s) não possui(em) CPF/CNPJ. Cadastre na seção Cadastros > Participantes. Os participantes são: ${this.generateParticipantsString(docsNotBookkepping)}`;

    const dialogRef = this.dialog.open(BasicAlertComponent, {data: dialogText});
    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogText.button) {
        if (docs.length) {
          this.postObject(docs, button);
        } else {
          this.snackbar.openLong('Não há nenhum documento para escriturar!', 'erro');
          button.disabled = false;
        }
      } else {
        button.disabled = false;
      }
    });
  }

  generateParticipantsString(docs) {
    return docs.map(doc => doc.pessoaID.nome).join(', ');
  }

  postObject(docs, button) {
    button.disabled = true;
    this.postingProvider.getDocIdsByDfeIds(docs.map (doc => doc.dfeId)).then(result => {
      const docsToPost = docs.map(doc => {
        doc.docId = result[doc.dfeId];
        return this.setObjectToPost(doc);
      });

      this.postingProvider.newPostingInBatch({docs: docsToPost}).then(() => {
        this.snackbar.openLong('Documentos escriturados com sucesso!', 'success');
        this.activeModal.close(true);
      }).catch(error => {
        button.disabled = false;
        this.snackbar.openLong('Não foi possível inserir os documentos! ' + error.error_description, 'erro');
      });
    }).catch(error => {
      button.disabled = false;
      this.snackbar.openLong('Não foi possível inserir o documentos! ' + error.error_description, 'erro');
    });
  }

  setObjectToPost(docSelect) {
    const formData = this.lancamentoForm.getRawValue();
    const doc = new Doc();
    doc.companyId = +localStorage.getItem('idEmpresa');
    doc.participantId = docSelect.pessoaID.id;
    doc.number = docSelect.numeroDfe;
    doc.propertyCode = docSelect.propertyCode ? docSelect.propertyCode : formData.imovel.propertyCode;
    doc.docType = "INVOICE";
    doc.receiptFileIds = this.receipts.map(receipt => receipt.id);
    doc.dfeId = docSelect.dfeId;
    doc.id = docSelect.docId;
    doc.postings = docSelect.lancamentos.map(lancamento =>
      this.convertLancamentosToPostAndPut(lancamento, doc.companyId, formData, docSelect)
    );
    return doc;
  }

  getAccountPlanDescription(accountPlan: AccountPlanDTOResponse): string {
    return accountPlan.description.replace(accountPlan.customId + ' - ', '');
  }


  checkIfValueExist(value, variable) {
    if (value === undefined || value === null) {
      return '';
    } else {
      switch (variable) {
        case 'name':
          if (value.name !== undefined) {
            return value.name.split(' -')[0];
          } else {
            return '';
          }
        case 'description':
          if (value.description !== undefined) {
            return this.getAccountPlanDescription(value);
          } else {
            return '';
          }
      }
    }
  }

  getHistoricTag(tag, docSelect, documento): string {
    switch (tag) {
      case '@participante@':
        return docSelect.pessoaID.name.toUpperCase();
      case '@participante-CpfCnpj@':
        ////console.log(docSelect)
        if(docSelect.pessoaID && docSelect.pessoaID.cpfCNPJParticipant){
          return docSelect.pessoaID.cpfCNPJParticipant
         }
      case '@numero-documento@':
        return docSelect.numeroDfe;
      case '@conta@':
        return this.checkIfValueExist(documento.categoria, 'description').trim();
      case '@mes@':
        return moment(documento.dataPagamento).locale('pt-br').format('MMM').toUpperCase();
      case '@ano@':
        return moment(documento.dataPagamento).format('Y');
      case '@ano-anterior@':
        return moment(documento.dataPagamento).subtract(1, 'year').format('Y');
      case '@mes-anterior@':
        return moment(documento.dataPagamento).subtract(1, 'month').locale('pt-br').format('MMM').toUpperCase();
      case '@mes-e-ano-anterior@':
        return moment(documento.dataPagamento).subtract(1, 'month').locale('pt-br').format('MMM Y').toUpperCase();
      default:
        return tag;
    }
  }

  convertLancamentosToPostAndPut(lancamento, companyID, formData, docSelect) {
    let posting = new Posting();
    const { historico, modelo } = this.individualHistoricForm.controls[0].controls;
    const historicData = lancamento.historic || {
      historico: historico.value.trim(),
      modelo: modelo.value.trim()
    };

    posting.historic = JSON.stringify(historicData).normalize('NFD').replace(/[^\x00-\x7F]/g, "").replace(/[\u0300-\u036f]/g, '');
    posting.companyId = companyID;
    posting.featureType = formData.categoria.featureType;
    posting.accountsChartId = lancamento.accountsChartId ? lancamento.accountsChartId : formData.categoria.id;
    posting.planPaymentId = lancamento.id;
    posting.groupersIds = this.groupers.map(grouper => grouper.id);
    posting.bankAccountId = lancamento.bankAccountId ? lancamento.bankAccountId : formData.contabancaria ? formData.contabancaria.id : null;
    posting.isDigital = localStorage.getItem('livroCaixa') === '1';
    posting.amountPaid = this.descontoProporcionalAtivo
      ? (+lancamento.valor * +this.valorProporcional).toFixed(2).toString()
      : lancamento.valor;
    posting.docId = docSelect.docId;
    posting.paymentDate = this.dateUtil.removeTimeZone(this.bookkeepingDuplicateDate ? lancamento.vencimento : formData.dataPagamento);
    posting.postingReleaseType = ReleaseType[lancamento.accountsChart? lancamento.accountsChart.releaseType.toString() : formData.categoria.releaseType];

    return posting;
  }

  editPaymentPlan(pagamentoPlanejado: Duplicatas[], dfeId, dfeIdParcela?, total?) {
    console.log(dfeIdParcela)
    pagamentoPlanejado.forEach((dup) => {
      if (!dup.escriturada) {
        this.selection.deselect(dup);
      }
    });
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(PagamentoPlanejadoComponent, modalOptions);
    modalRef.componentInstance.duplicatesPlanned = pagamentoPlanejado;
    modalRef.componentInstance.total = total
    if (dfeIdParcela) {
      modalRef.componentInstance.dfeId = dfeIdParcela;
    } else {
      modalRef.componentInstance.dfeId = dfeId;
    }
    modalRef.result.then(() => {
      this.toogleAll = false;
      let doc = this.documentosExistentes.find((doc: ConsultaDfe) => doc.id == dfeId);
      doc.pagamentoPlanejado.forEach((dup: any) => {
        if (!dup.historic) {
          dup.historic = {
            historico: this.individualHistoricForm.controls[0].controls.historico.value,
            modelo: this.individualHistoricForm.controls[0].controls.modelo.value
          }
        }
      })
      this.masterToggle(doc);
      this.changeMade = true;
      this.calculaValorTotal();
    }, () => {
      this.toogleAll = false;
      let doc = this.documentosExistentes.find((doc: ConsultaDfe) => doc.id == dfeId);
      doc.pagamentoPlanejado.forEach((dup: any) => {
        if (!dup.historic) {
          dup.historic = {
            historico: this.individualHistoricForm.controls[0].controls.historico.value,
            modelo: this.individualHistoricForm.controls[0].controls.modelo.value
          }
        }
      })
      this.masterToggle(doc);
      this.changeMade = true;
      this.calculaValorTotal();
    });
  }

  removeDfe(index) {
    if (this.documentosExistentes.length == 1) {
      Swal.fire({
        title: 'Atenção!',
        text: 'É obrigatório pelo menos um documento.',
        icon: 'warning'
      });
      return;
    }
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover a nota fiscal ' + this.documentosExistentes[index].numeroDoc + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, remover',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        this.documentosExistentes.splice(index, 1);
        this.individualHistoricForm.removeAt(index);
        this.calculaValorTotal();
      }
    });
  }

  checkWorkDate(data: any, button) {
    const workDate = localStorage.getItem('workDate');
    const startMonth = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').subtract(1, 'day');
    const endMonth = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').add(1, 'day');
    const isDateInRange = moment.tz(data.value, 'America/Sao_Paulo').isBetween(startMonth, endMonth);

    if (isDateInRange || this.bookkeepingDuplicateDate) {
      this.postNewDocuments(button);
    } else {
      this.dialog.open(DataTrabalhoDialog, {disableClose: true}).afterClosed().subscribe((result: any) => {
        if (result == undefined || result != 'cancelou') {
          this.postNewDocuments(button);
        } else {
          button.disabled = false;
        }
      });
    }
  }

  protected filterBankAccount() {
    if (!this.contas) {
      return;
    }
    // get the search keyword
    let search = this.bankAccountFilterCtrl.value;
    if (!search) {
      this.filter_bankAccount.next(this.contas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_bankAccount.next(
      this.contas.filter(bank => bank.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterAccountPlan() {
    if (!this.categorias) {
      return;
    }
    // get the search keyword
    let search = this.accountPlanFilterCtrl.value;
    if (!search) {
      this.filter_planAccount.next(this.categorias.slice());
    } else {
      // filter the planAccount
      search = search.toLowerCase();
      this.filter_planAccount.next(
        this.categorias.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }

  protected filterHistoric() {
    if (!this.historicos) {
      return;
    }
    // get the search keyword
    let search = this.historicFilterCtrl.value;
    if (!search) {
      this.filter_historic.next(this.historicos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_historic.next(
      this.historicos.filter(historic => historic.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProperties() {
    if (!this.imoveis) {
      return;
    }
    // get the search keyword
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.imoveis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_property.next(
      this.imoveis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private setFirstDuplicatesSelected() {
    this.documentosExistentes.forEach((documento: ConsultaDfe) => {
      if (documento.pagamentoPlanejado) {
        //
        documento.pagamentoPlanejado.sort(function (a, b) {
          return (a.vencimento) < (b.vencimento) ? -1 : (a.vencimento) > (b.vencimento) ? 1 : 0;
        });
        for (let i = 0; i < documento.pagamentoPlanejado.length; i++) {
          if (!documento.pagamentoPlanejado[i].escriturada) {
            this.selection.select(documento.pagamentoPlanejado[i]);
            i = documento.pagamentoPlanejado.length;
          }
        }
      } else {
        this.ordenaSemelhantes()
        this.masterToggle(documento);
        this.setFirstDuplicatesSelected()
      }
    });
  }

  ordenaSemelhantes() {
    this.documentosExistentes.forEach((doc: ConsultaDfe2, z) => {
      const pagamentoPlanejado = this.documentosExistentes
        .filter(doc2 => doc.parcelaId === doc2.parcelaId)
        .map((doc2: ConsultaDfe2) => ({
          numero: doc2.numberParcela,
          vencimento: doc2.dataExpiracao,
          valor: doc2.valorTotal,
          id: doc2.id,
          escriturada: doc2.pago,
        }));

      this.documentosExistentes[z].valorTotalFormatado = doc.valorDFE;
      this.documentosExistentes[z].pagamentoPlanejado = pagamentoPlanejado;
    })

    const seen = new Set();
    this.documentosExistentes = [...this.documentosExistentes.filter(doc => !seen.has(doc.parcelaId) && seen.add(doc.parcelaId))];
    this.setIndividualHistorics('DEFAULT');
  }

  getDateLocks() {
    this.dateLockProvider.getDateLocks().then((value: DateLock[]) => {
      this.locks = value;
    });
  }

  isLocked() {
    if (this.bookkeepingDuplicateDate) {
      for (let lancamento of this.lancamentoForm.value.lancamentos) {
        for (let lock of this.locks) {
          if (moment(lancamento.vencimento).isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
            return true;
          }
        }
      }
    } else {
      for (let lock of this.locks) {
        if (this.lancamentoForm.value.dataPagamento.isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
          return true;
        }
      }
    }
    return false;
  }

  getFileDfe(id, location) {
    this.carregando = true;
    this.dfeProvider.getDfeByID(id).then((dfe: any) => {
      const document = {
        xml: atob(dfe.dfeBytes),
        modelo: dfe.modelType,
        chave: dfe.key
      };

      switch (location) {
        case 'modal':
          this.consultaPdfXml.getPdfModal(document).then((result) => {
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
            console.error(result);
            this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
              this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
        case 'blank':
          this.consultaPdfXml.getPdf(document).then((result) => {
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
            console.error(result);
            this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
              this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
      }
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
      console.error(result);
      this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
    });
  }
  vinculateStockProducts(documento: any) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ProdutosEstoqueComponent, modalOptions);
    modalRef.componentInstance.document = documento;
    modalRef.componentInstance.properties = this.imoveis;
    modalRef.componentInstance.useDestinatary = this.saleDfe;
    modalRef.result.then((result) => {
      if (result != "clear") {
        documento.stockProducts = result;
      } else {
        documento.stockProducts = null;
      }
    }, () => {
    });
  }

}

class Doc {
  id: number;
  companyId: number;
  number: number;
  docType: string;
  propertyCode: string;
  participantId: string;
  postings: Posting[];
  docTypeFormated: string;
  receiptFileIds: any[] = [];
  dfeId: number;
}

class Posting {
  id: number;
  companyId: number;
  amountPaid: string;
  planPaymentId: number;
  historic: any;
  accountsChartId: number;
  paymentDate: string;
  postingReleaseType: string;
  groupersIds: number[];
  bankAccountId: number;
  isDigital: boolean;
  docId: number;
  featureType: string;
  paymentId: number;
}

export function LengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value.length > 0) {
    // return {typeValue: false};
  } else {
    return {typeValue: true};
  }
  return null;

}
