import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Snackbar} from 'src/util/snackbar';
import {AutomationProvider} from "../../../../providers/automation/automation";

@Component({
  selector: 'app-clone-automacao',
  templateUrl: './clone-automacao.component.html',
  styleUrls: ['./clone-automacao.component.scss']
})
export class CloneAutomacaoComponent implements OnInit {

  public loading: boolean = false;
  public existingAutomationGroupId: any = null;
  public automationDescription: string = '';
  public automationService: string = '';

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public fb: FormBuilder,
    public automationProvider: AutomationProvider
  ) {
  }

  ngOnInit() {
  }

  createClonedAutomation() {
    this.loading = true;
    this.automationProvider.cloneAutomationByCode(this.existingAutomationGroupId, this.automationDescription, this.automationService)
      .then((result: any[]) => {
        this.snackBar.open('Automação clonada com sucesso', 'sucesso');
        this.loading = false;
        this.activeModal.close();
      })
      .catch((error) => {
        this.snackBar.openLong(error.message, 'erro');
        this.loading = false;
      });
  }


  checkDescriptionValidity() {
    return this.automationDescription.length > 3;
  }

}
