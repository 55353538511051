import { Component, OnInit } from '@angular/core';
import {Maintenance} from "../../../../model/dto/status/Maintenance";
import { Incident } from 'src/model/dto/status/Incident';
import {MaintenanceProvider} from "../../../../providers/status/maintenanceProvider";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {CheckPermission} from "../../../../service/checkPermission";
import Swal from "sweetalert2";

@Component({
  selector: 'app-service-status',
  templateUrl: './service-status.component.html',
  styleUrls: ['./service-status.component.scss']
})
export class ServiceStatusComponent implements OnInit {

  maintenance: Maintenance[] = [];
  newsObj = null;

  constructor(private checkPermission: CheckPermission,
              private maintenanceProvider: MaintenanceProvider,
              private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    // this.getOverallData();
    this.getStatus();
    this.getIncidents();
  }

  warningStatus() {
    return JSON.parse(localStorage.getItem('warnings'));
  }

  toggleWarnings() {
    const warnings = localStorage.getItem('warnings') === 'true';
    localStorage.setItem('warnings', warnings ? 'false' : 'true');
  }

  getOverallData(){
    this.maintenanceProvider.getOverallData().then((value: any) => {
    });
  }

  getIncidents(){
    this.maintenanceProvider.getAllIncident().then((value: Incident[]) => {
      let incident = value.filter(x => x.name.includes("[NEWS_FARMCONT]"));
      let version = value.filter(x => x.name.includes("[VERSION_FARMCONT]"));

      if(incident.length == 0) return;
      this.newsObj = {
        title: incident[0].name.split("[NEWS_FARMCONT]")[1].replace(/_/g, " "),
        description: incident[0].updates[0].message.split("§")[0],
        link: incident[0].updates[0].message.split("§")[1]
      }
      this.newsObj.link != undefined ? this.newsObj.link = this.domSanitizer.bypassSecurityTrustUrl(this.newsObj.link) : this.newsObj.link = null;
       if(version&&version[0].updates[0].message != require('../../../../../package.json').version) {
       this.fireUpdateSwal();
       }
    });
  }

  fireUpdateSwal() {
    Swal.fire({
      title: "Atualização disponível!",
      text: "Recomendamos atualizar a aplicação para garantir o melhor desempenho e obter acesso às novas funcionalidades e correções.",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      cancelButtonText: 'Ignorar',
      confirmButtonText: "Atualizar"
    }).then(result => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => registration.unregister());
        }).then(() => {
          console.log("Service Worker desativado. Limpando cache...");
          return caches.keys().then(cacheNames => {
            return Promise.all(cacheNames.map(cache => caches.delete(cache)));
          });
        }).then(() => {
          console.log("Cache limpo. Recarregando página...");
          window.location.reload(true);
        });
      }
    });
  }

  getStatus() {
    this.maintenance = [];
    this.maintenanceProvider.getAllMaintenance().then((value: Maintenance[]) => {
      let user_roles = this.checkPermission.getRoles();
      value.forEach(x => {
        if (x.name.includes("[MAINTENANCE_FARMCONT]")) {
          x.name = x.name.split("[MAINTENANCE_FARMCONT]")[1].replace(/_/g, " ");
          let maintenance_roles = x.components.filter(y => y.name.includes("ROLE_")).map(y => y.name);
          x.components = x.components.filter(y => !y.name.includes("ROLE_"));
          if (!maintenance_roles.length || maintenance_roles.some(r => user_roles.includes(r))) {
            this.maintenance.push(x);
          }
        }
      });
    });
  }
}
