import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AutomationProvider} from 'src/providers/automation/automation';
import {FolderProvider} from 'src/providers/folder/folder';
import {UserProvider} from 'src/providers/user/user';
import {AutomationsConfig} from 'src/util/automations';
import {Snackbar} from 'src/util/snackbar';
import Swal from "sweetalert2";
import {AccountProvider} from "../../../../providers/account/account";
import {HistoricProvider} from "../../../../providers/bookkeeping/historic/historic";
import * as moment from "moment";
import {ReleaseType} from "../../../../model/enum/ReleaseType";
import {ParticipantProvider} from "../../../../providers/participant/participant";

@Component({
  selector: 'app-cadastro-automacao',
  templateUrl: './cadastro-automacao.component.html',
  styleUrls: ['./cadastro-automacao.component.scss']
})
export class CadastroAutomacaoComponent implements OnInit {


  protected _onDestroy = new Subject<void>();
  public loading: boolean = false;
  public panelExpanded: boolean = true;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @Input() public existingAutomation = null;

  // STEP Action
  public actionList: any = [];
  public automationAction: any = null;
  public planAccount = {
    values: [],
    selected: null
  }

  // STEP Condition
  public automationConditionFormArray: any = new FormArray([]);
  public automationConditionSelectsValues = {
    participantFilterCtrl: new FormControl(),
    filter_participant: new ReplaySubject(),
    participants: [],
    participantLoaded: false,
  }

  // STEP Companies
  public automationActionValuesList: any = [];
  public automationActionFormArray: any = new FormArray([]);
  public officeCompanies: any[] = [];
  public companyFilterCtrl: FormControl = new FormControl();
  public filtered_companies: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public bankAccountFilterCtrl: any = new FormArray([]);
  public filter_bankAccount: any = [];
  public propertyFilterCtrl: any = new FormArray([]);
  public filter_property: any = [];
  public accountPlanFilterCtrl: any = new FormArray([]);
  public filter_accountPlan: any = [];
  public historicFilterCtrl: any = new FormArray([]);
  public filter_historic: any = [];


  //STEP 4 →  Review
  public automationGroupId: string = null;
  public automationDescription: string = "";

  constructor(
    public automationsConfig: AutomationsConfig,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public fb: FormBuilder,
    public userProvider: UserProvider,
    public folderProvider: FolderProvider,
    public automationProvider: AutomationProvider,
    public planAccountProvider: AccountProvider,
    public historicProvider: HistoricProvider,
    public participantProvider: ParticipantProvider
  ) {
  }

  ngOnInit() {
    this.actionList = this.automationsConfig.actionList();
    Promise.all([this.initializeConditionSelectValuesParticipant(), this.initializeCompanies()]).then(() => {
      if (this.existingAutomation) {
        this.existingAutomationToForm();
      }
    });
  }

  getPlanAccountInfo() {
    this.loading = true;
    this.participantProvider.getAccountParticipants(this.planAccount.selected.id).then((participants: any) => {
       if (participants.length && !this.existingAutomation) {
        this.addAutomationConditionFormGroup();
        const formGroup = this.automationConditionFormArray.controls.at(-1);
        const conditionName = this.planAccount.selected.featureType === 'EXPENSE' ? 'Emitente - CPF/CNPJ' : 'Destinatário - CPF/CNPJ';
        const conditionOperator = this.planAccount.selected.featureType === 'EXPENSE' ? 'issuerCpfCnpjs' : 'addressedCpfCnpjs';
        const conditionValue = this.automationConditionSelectsValues.participants.filter((item) => (participants.map((participant) => participant.cpfCNPJParticipant)).includes(item.value));
        formGroup.controls.condition.setValue(this.automationAction.conditions.find(item => item.name === conditionName));
        formGroup.controls.conditionOperator.setValue(conditionOperator);
        formGroup.controls.conditionExtraValues.setValue([...conditionValue]);
       formGroup.controls.conditionValue.setValue(" ");
       }
      this.loading = false;
    }).catch(error => {
      this.loading = false;
      this.snackBar.openLong("Erro ao buscar participantes da conta selecionada", "erro");
    });
  }


  existingAutomationToForm() {
    const automationType = this.existingAutomation[0].automationType;
    this.automationAction = this.actionList.find(action => action.action === automationType);
    this.automationGroupId = this.existingAutomation[0].groupId;
    this.automationDescription = this.existingAutomation[0].description;
    this.selectAutomationType();
    this.setExistingAutomationConditionToForm();
    this.setExistingAutomationValueToForm();
  }

  setExistingAutomationConditionToForm() {
    this.automationAction.conditions.forEach(condition => {
      Object.entries(condition.operators).forEach(([operator]) => {
        if (this.existingAutomation[0][operator] !== undefined) {
          this.addAutomationConditionFormGroup();
          const formGroup = this.automationConditionFormArray.controls.at(-1);
          formGroup.controls.condition.setValue(condition);
          formGroup.controls.conditionOperator.setValue(operator);
          const conditionValue = this.existingAutomation[0][operator];
          if (Array.isArray(conditionValue)) {
            formGroup.controls.conditionExtraValues.setValue(this.setConditionExtraValues(formGroup, conditionValue));
            formGroup.controls.conditionValue.setValue(" ");
          } else {
            formGroup.controls.conditionValue.setValue(conditionValue);
          }
        }
      });
    });
  }

  setConditionExtraValues(formGroup, conditionValue){
    console.log(formGroup)
    console.log(conditionValue)
    if(formGroup.controls.condition.value.inputType.includes('select')){
      if(formGroup.controls.condition.value.inputType.includes('participant')){
        return this.automationConditionSelectsValues.participants.filter((item) => conditionValue.includes(item.value));
      }
    } else {
      return [...conditionValue]
    }
  }

  setExistingAutomationValueToForm() {
    this.existingAutomation.forEach((automation, index) => {
      const company = this.officeCompanies.find(company => company.id === automation.companyId);
      this.addAutomationActionFormGroup(company);
      this.companySelectActionValue(index, company).then(() => {
        const formGroup = this.automationActionFormArray.controls[index];
        formGroup.controls.id.setValue(automation.id);
        formGroup.controls.priority.setValue(automation.priority);
        formGroup.controls.startDate.setValue(moment(automation.startDate).toDate());
        formGroup.controls.endDate.setValue(moment(automation.endDate).toDate());
        formGroup.controls.isActive.setValue(automation.isActive);
        if (this.automationAction.action === "POSTING_DFE") {
          const valuesList = this.automationActionValuesList[index];
          formGroup.controls.bankAccount.setValue(valuesList.bankAccounts.find(bankAccount => bankAccount.id === automation.bankAccountId));
          formGroup.controls.property.setValue(valuesList.properties.find(property => property.propertyCode === automation.propertyCode));
          formGroup.controls.historic.setValue(valuesList.historics.find(historic => historic.id === automation.historicId));
          formGroup.controls.participant.setValue(automation.participant);
        } else if (["MOVE_DFE_TO_FOLDER", "MOVE_CANCELLED_DFE_TO_FOLDER"].includes(this.automationAction.action)) {
          formGroup.controls.value.setValue(this.automationActionValuesList[index].find(folder => folder.id === automation.folderId));
        }
      });
    });
  }

  selectAutomationType(){
    if(this.automationAction.action === "POSTING_DFE"){
      this.loading = true;
      this.planAccountProvider.getAllAccountsLeafs().then((result) => {
        this.loading = false;
        if(this.existingAutomation){
          this.planAccount.values = [...(result as any)];
          this.planAccount.selected = this.planAccount.values.find((item) => item.id == this.existingAutomation[0].planAccountId);
        }
        this.createFilterControl(this.accountPlanFilterCtrl, this.filter_accountPlan, result, 0);
      }).catch(error => {
        this.loading = false;
        this.snackBar.openLong("Erro ao buscar contas", "erro");
      });
    }
  }

  addAutomationConditionFormGroup() {
    this.automationConditionFormArray.push(this.fb.group({
      condition: [null, Validators.required],
      conditionOperator: [null, Validators.required],
      conditionValue: [null],
      conditionExtraValues: [[]],
    }));
  }

  removeAutomationConditionFormGroup(index: number) {
    this.automationConditionFormArray.removeAt(index);
  }

  removeAllAutomationConditionFormGroup() {
    this.automationConditionFormArray.clear();
  }

  setConditionValidator(value, formGroup, index) {
    const isMultiple = value.inputType.includes('multiple');
    formGroup.controls.conditionExtraValues.setValidators(isMultiple ? Validators.required : null);
    formGroup.controls.conditionValue.setValidators(isMultiple ? null : Validators.required);
  }


  conditionParticipantSelect(formGroup, conditionValue){
    const conditionExtraValues = formGroup.controls.conditionExtraValues.value;
    if (!conditionExtraValues.includes(conditionValue)) {
      conditionExtraValues.push(conditionValue);
      formGroup.controls.conditionExtraValues.setValue(conditionExtraValues);
    } else {
      this.snackBar.openLong("Valor já adicionado", "erro");
    }
    formGroup.controls.conditionValue.setValue("");
}


  handleKeyDownConditionValue(formGroup, event: KeyboardEvent) {
    const conditionValue = formGroup.controls.conditionValue.value.trim().replace(/\D/g, "");
    if ((event.key === " " || event.key === 'Enter') && conditionValue) {
      event.preventDefault();
      const conditionExtraValues = formGroup.controls.conditionExtraValues.value;
      if (!conditionExtraValues.includes(conditionValue)) {
        conditionExtraValues.push(conditionValue);
        formGroup.controls.conditionValue.setValue("");
        formGroup.controls.conditionExtraValues.setValue(conditionExtraValues);
      } else {
        this.snackBar.openLong("Valor já adicionado", "erro");
      }
    }
  }

  removeConditionExtraValue(formGroup, index) {
    const extraValues = formGroup.controls.conditionExtraValues.value;
    extraValues.splice(index, 1);
    if (!extraValues.length) {
      formGroup.controls.conditionExtraValues.setErrors(Validators.required);
    }
  }

  addAutomationActionFormGroup(company?) {
    const formGroupConfig = {
      id: [null],
      priority: [null],
      company: [company || null, Validators.required],
      startDate: [moment().startOf('year')],
      endDate: [moment().endOf('year')],
      isActive: [true, Validators.required],
    };

    if (this.automationAction.action === "MOVE_DFE_TO_FOLDER" || this.automationAction.action === "MOVE_CANCELLED_DFE_TO_FOLDER") {
      formGroupConfig['value'] = [null, Validators.required];
      this.automationActionValuesList.push(null);
    } else if (this.automationAction.action === "POSTING_DFE") {
      Object.assign(formGroupConfig, {
        bankAccount: [null, Validators.required],
        planAccount: [null],
        property: [null, Validators.required],
        historic: [null, Validators.required],
        participant: [false, Validators.required],
        releaseType: [null],
      });
      this.automationActionValuesList.push({bankAccounts: [], properties: []});
    }

    const formGroup = this.fb.group(formGroupConfig);
    this.automationActionFormArray.push(formGroup);
  }

  removeAutomationActionFormGroup(index: number) {
    const formGroup = this.automationActionFormArray.controls[index];
    const id = formGroup.controls.id.value;

    const removeFormGroup = () => {
      this.automationActionFormArray.removeAt(index);
      this.automationActionValuesList.splice(index, 1);
      if (this.automationAction.action === "POSTING_DFE") {
        [this.bankAccountFilterCtrl, this.filter_bankAccount, this.propertyFilterCtrl, this.filter_property, this.historicFilterCtrl, this.filter_historic].forEach(arr => arr.splice(index, 1));
      }
    };

    if (id) {
      Swal.fire({
        title: 'Você tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.automationProvider.deleteAutomation(id, this.automationAction.service)
            .then(() => {
              removeFormGroup();
              Swal.fire('Excluído!', 'A automação foi excluída.', 'success');
            })
            .catch(error => {
              this.snackBar.openLong(error.message, 'erro');
            });
        }
      });
    } else {
      removeFormGroup();
    }
  }

  removeAllAutomationActionFormGroup() {
    this.automationActionFormArray.clear();
    this.automationActionValuesList = [];
    if (this.automationAction.action === "POSTING_DFE") {
      const arraysToClear = [
        this.bankAccountFilterCtrl,
        this.filter_bankAccount,
        this.propertyFilterCtrl,
        this.filter_property,
        this.historicFilterCtrl,
        this.filter_historic
      ];
      arraysToClear.forEach(arr => arr = []);
    }
  }

  companySelectActionValue(index: number, event) {
    this.automationActionFormArray.controls.forEach((group, i) => {
      if (group.controls.company.value.id === event.id && i !== index) {
        this.snackBar.openLong("Empresa já selecionada", "erro");
        this.automationActionFormArray.controls[index].controls.company.setValue(null);
        return;
      }
    });

    if (this.automationAction.action === "MOVE_DFE_TO_FOLDER" || this.automationAction.action === "MOVE_CANCELLED_DFE_TO_FOLDER") {
      this.loading = true;
      return this.folderProvider.getFoldersByCompanyId(this.automationActionFormArray.controls[index].controls.company.value.id)
        .then(folders => {
          this.automationActionValuesList[index] = folders;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.snackBar.openLong("Erro ao buscar pastas", "erro");
        });
    } else if (this.automationAction.action === "POSTING_DFE") {
      this.loading = true;
      return this.automationProvider.getPostingData(event.id).then((result: any) => {
        this.setPostingData(result, index);
      });
    }
  }

  setPostingData(value, index) {
    this.automationActionValuesList[index] = {
      bankAccounts: value.bankAccounts || [],
      accountPlans: value.accountPlans || [],
      properties: value.properties || [],
      historics: [{id: 0, description: 'HISTÓRICO PADRÃO FARMCONT'}, ...(value.historics || [])]
    };

    const formGroup = this.automationActionFormArray.controls[index];
    formGroup.get('bankAccount').setValue(this.automationActionValuesList[index].bankAccounts.find(item => item.default) || null);
    formGroup.get('property').setValue(this.automationActionValuesList[index].properties.find(item => item.default) || null);
    formGroup.get('historic').setValue(this.planAccount.selected.historicId && !formGroup.id ? this.automationActionValuesList[index].historics.find(item => item.id === this.planAccount.selected.historicId) : this.automationActionValuesList[index].historics.find(item => item.id === 0) || null);

    this.initializePostingDataSearch(index);
    this.loading = false;
  }

  async addAllActionCompanies() {
    const toRemove = this.automationActionFormArray.controls
      .filter(group => group.controls.company.value)
      .map(group => group.controls.company.value.id);

    const companiesToAdd = this.officeCompanies.filter(company => !toRemove.includes(company.id));

    for (const company of companiesToAdd) {
      this.addAutomationActionFormGroup(company);
      const index = this.automationActionFormArray.controls.length - 1;
      this.loading = true;
      try {
        if (["MOVE_DFE_TO_FOLDER", "MOVE_CANCELLED_DFE_TO_FOLDER"].includes(this.automationAction.action)) {
          this.automationActionValuesList[index] = await this.folderProvider.getFoldersByCompanyId(company.id);
        } else if (this.automationAction.action === "POSTING_DFE") {
          await this.automationProvider.getPostingData(company.id).then((result: any) => {
            this.setPostingData(result, index);
          });
        }
      } catch (error) {
        this.snackBar.openLong("Erro ao buscar dados", "erro");
      } finally {
        this.loading = false;
      }
    }
  }

  checkConditionStepValidity() {
    return this.automationConditionFormArray.controls.length > 0 &&
      this.automationConditionFormArray.controls.every(group => group.valid);
  }

  checkValueStepValidity() {
    return this.automationAction && this.automationActionFormArray.controls.length > 0 &&
      this.automationActionFormArray.controls.every(group => group.valid);
  }

  checkPostingFormValidity(index?) {
    return this.automationActionFormArray.controls[index].valid;
  }

  checkRevisionStepValidity() {
    return this.automationDescription.length > 3;
  }

  postAutomation() {
    this.automationProvider.postAutomation(this.formatAutomationFormToSubmit(), this.automationAction.service)
      .then(result => this.activeModal.close(result))
      .catch(error => this.snackBar.openLong(error.message, 'erro'));
  }

  putAutomation() {
    this.automationProvider.putAutomation(this.formatAutomationFormToSubmit(), this.automationAction.service)
      .then(result => this.activeModal.close(result))
      .catch(error => this.snackBar.openLong(error.message, 'erro'));
  }

  formatAutomationFormToSubmit() {
    let automations = this.automationActionFormArray.controls.map((group) => {
      const automation = {
        id: group.controls.id.value,
        priority: group.controls.priority.value,
        automationType: this.automationAction.action,
        companyId: group.controls.company.value.id,
        isActive: group.controls.isActive.value,
        startDate: group.controls.startDate.value.toISOString(),
        endDate: group.controls.endDate.value.toISOString(),
        groupId: this.automationGroupId,
        description: this.automationDescription,
        ...(this.automationAction.action === "MOVE_DFE_TO_FOLDER" && {folderId: group.controls.value.value.id}),
        ...(this.automationAction.action === "MOVE_CANCELLED_DFE_TO_FOLDER" && {folderId: group.controls.value.value.id}),
        ...(this.automationAction.action === "POSTING_DFE" && {
          bankAccountId: group.controls.bankAccount.value.id,
          propertyCode: group.controls.property.value.propertyCode,
          historicId: group.controls.historic.value.id,
          participant: group.controls.participant.value,
          planAccountId: this.planAccount.selected.id,
          releaseType: this.planAccount.selected.releaseType
        }),
      };

      this.automationConditionFormArray.controls.forEach(conditionGroup => {
        const operator = conditionGroup.controls.conditionOperator.value;
        const extraValues = conditionGroup.controls.conditionExtraValues.value;
        const conditionValue = conditionGroup.controls.conditionValue.value;
        const isSelect = conditionGroup.controls.condition.value.inputType.includes('select');

        automation[operator] = extraValues.length > 0
          ? (isSelect ? extraValues.map(item => item.value) : extraValues)
          : conditionValue;
      });

      return automation;
    });

    const generalPriorityItem = automations.find(item => item.priority !== null);
    const generalPriority = generalPriorityItem ? generalPriorityItem.priority : undefined;

    if (generalPriority !== undefined) {
      automations = automations.map(item => ({
        ...item,
        priority: item.priority === null ? generalPriority : item.priority
      }));
    }
    return automations;
  }

  formatConditionValueToDisplay(value, mod?) {
    switch (typeof value) {
      case "boolean":
        return value ? "Verdadeiro" : "Falso";
      case "number":
        return new Intl.NumberFormat('pt-BR', {
          ...(mod.includes('currency') ? {style: 'currency'} : {}),
          currency: 'BRL'
        }).format(value);
      case "object":
        if (Array.isArray(value)) {
          return `{${value.map(this.formatConditionValueToDisplay).join(", ou ")}}`;
        } else {
          return `${value.description} - ${value.value}`
        }
      default:
        return value;
    }
  }

  setDateAllCompanies() {
    const startDate = this.automationActionFormArray.controls[0].controls.startDate.value;
    const endDate = this.automationActionFormArray.controls[0].controls.endDate.value;
    this.automationActionFormArray.controls.forEach(group => {
      group.controls.startDate.setValue(startDate);
      group.controls.endDate.setValue(endDate);
    });
  }

  initializeCompanies() {
    this.loading = true;
    return this.userProvider.getAllCompanys().then((result: []) => {
      this.officeCompanies = result;
      this.filtered_companies.next(this.officeCompanies.slice());
      this.companyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterCompanies();
      });
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      this.snackBar.openLong("Erro ao buscar empresas", "erro");
    });
  }

  protected filterCompanies() {
    if (!this.officeCompanies) {
      return;
    }
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filtered_companies.next(this.officeCompanies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtered_companies.next(
      this.officeCompanies.filter(company => (company.name.toLowerCase().includes(search) || company.id == search))
    );
  }

  initializeConditionSelectValuesParticipant() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.participantProvider.getAllParticipants().then((result: any[]) => {
        const participants = result.map(item => ({
          description: item.name,
          value: item.cpfCNPJParticipant,
          id: item.id
        }))
        this.automationConditionSelectsValues.participants = participants;
        this.automationConditionSelectsValues.participantFilterCtrl.setValue('');
        this.automationConditionSelectsValues.filter_participant.next(participants.slice());
        this.automationConditionSelectsValues.participantFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            const search = this.automationConditionSelectsValues.participantFilterCtrl.value ? this.automationConditionSelectsValues.participantFilterCtrl.value.toLowerCase() : '';
            const filtered = participants.filter(value =>
              value.description.toLowerCase().includes(search) ||
              value.value.toLowerCase().includes(search)
            );
            this.automationConditionSelectsValues.filter_participant.next(filtered);
          });

        this.loading = false;
        resolve(true);
      }).catch(error => {
        this.loading = false;
        reject(false);
        this.snackBar.openLong("Erro ao buscar participantes", "erro");
      });
    })

  }

  initializePostingDataSearch(index) {
    this.createFilterControl(this.bankAccountFilterCtrl, this.filter_bankAccount, this.automationActionValuesList[index].bankAccounts, index);
    this.createFilterControl(this.propertyFilterCtrl, this.filter_property, this.automationActionValuesList[index].properties, index);
    this.createFilterControl(this.historicFilterCtrl, this.filter_historic, this.automationActionValuesList[index].historics, index);
  }

  createFilterControl (ctrlArray, filterArray, values, index){
    const control = new FormControl();
    ctrlArray[index] = control;
    filterArray.push(new ReplaySubject<any[]>(1));
    filterArray[index].next(values ? values.slice() : []);
    control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterItems(index, ctrlArray, filterArray, values));
  };

  private filterItems(index, ctrlArray, filterArray, items) {
    if (!items) return;
    let search = ctrlArray[index].value ? ctrlArray[index].value.toLowerCase() : '';
    filterArray[index].next(
      items.filter(item =>
        ['description', 'name', 'code', 'customId', 'propertyCode'].some(
          field => (item[field] ? item[field].toString().toLowerCase().includes(search) : false)
        )
      )
    );
  }

  protected readonly
  Object = Object;
  protected readonly ReleaseType = ReleaseType;
}
